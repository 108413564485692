import * as React from 'react';
import {Access} from "../../../../components/Access";
import {ProTable} from "@ant-design/pro-components";
import {Avatar, Typography} from "antd";
import dayjs from "dayjs";
import {numFormatterInt, numFormatterPercent} from "./YouTubeBenchmarks";
import {UserAuth} from "../../../../context/AuthContext";
import {useAtom} from "jotai";
import {monthlyViewsAtom} from "./atoms";

type Props = {};

export const MonthView = (props: Props) => {
    const [monthlyViews, refreshMonthlyViews] = useAtom(monthlyViewsAtom)
    const {can} = UserAuth();
    return (
        <div>
            <Access accessible={can('view', 'benchmarks::breakdown')} fallback={null}>
                <ProTable<API.Benchmarks.YouTubeMonthlyView>
                    // actionRef={actionRefMonthly}
                    cardBordered
                    rowKey={'channelID'}
                    search={false}
                    toolBarRender={false}
                    dataSource={monthlyViews.data}
                    columns={[
                        // {
                        //     key: 'index',
                        //     valueType: 'indexBorder',
                        //     align: 'center',
                        // },
                        {
                            key: 'image',
                            // valueType: 'indexBorder',
                            // align: 'center',
                            render: (dom, entity) => (
                                <Avatar
                                    src={`data:image/jpeg;base64,${entity?.image}`}
                                    style={{width: 34, height: 34}}
                                    shape={"circle"}
                                />
                            )
                        },

                        {
                            title: 'Channel',
                            dataIndex: 'channelName',
                            key: 'channelName',
                            sorter: (a, b) => a.channelName.localeCompare(b.channelName),
                            // render the name with an icon to open the channel in a new tab
                            render: (value, record) => (
                                <Typography.Link strong
                                                 href={`https://www.youtube.com/channel/${record.channelID}`}
                                                 target={'_blank'}
                                >{value}</Typography.Link>
                            )
                        },
                        {
                            title: 'Subscribers Lifetime',
                            dataIndex: 'totalSubscribersLifetime',
                            key: 'totalSubscribersLifetime',
                            render: (value) => numFormatterInt.format(+value),
                            sorter: (a, b) => a.totalSubscribersLifetime - b.totalSubscribersLifetime,
                        },
                        {
                            title: 'Subscribers',
                            dataIndex: 'subscribers',
                            key: 'subscribers',
                            render: (value) => numFormatterInt.format(+value),
                            sorter: (a, b) => a.subscribers - b.subscribers,
                        },
                        {
                            title: 'Views Lifetime',
                            dataIndex: 'totalViewsLifetime',
                            key: 'totalViewsLifetime',
                            render: (value) => numFormatterInt.format(+value),
                            sorter: (a, b) => a.totalViewsLifetime - b.totalViewsLifetime,
                        },
                        {
                            title: 'Views',
                            dataIndex: 'views',
                            key: 'views',
                            render: (value) => numFormatterInt.format(+value),
                            sorter: (a, b) => a.views - b.views,
                        },
                        {
                            title: 'Organic Views',
                            dataIndex: 'organicViews',
                            key: 'organicViews',
                            render: (value) => numFormatterInt.format(+value),
                            sorter: (a, b) => a.organicViews - +b.organicViews,
                        },
                        {
                            title: 'Organic Views %',
                            dataIndex: 'organicViewsPercentage',
                            key: 'organicViewsPercentage',
                            render: (value) => numFormatterPercent.format(+value),
                            sorter: (a, b) => a.organicViewsPercentage - b.organicViewsPercentage,
                        },
                        {
                            title: 'Watch Time Hours Lifetime',
                            dataIndex: 'totalWatchTimeHoursLifetime',
                            key: 'totalWatchTimeHoursLifetime',
                            render: (value) => numFormatterInt.format(+value),
                            sorter: (a, b) => a.totalWatchTimeHoursLifetime - b.totalWatchTimeHoursLifetime,
                        },
                        {
                            title: 'Watch Time Hours',
                            dataIndex: 'watchTimeHours',
                            key: 'watchTimeHours',
                            render: (value) => numFormatterInt.format(+value),
                            sorter: (a, b) => a.watchTimeHours - b.watchTimeHours,
                        },
                        {
                            title: 'Organic Watch Time Hours',
                            dataIndex: 'organicWatchTimeHours',
                            key: 'organicWatchTimeHours',
                            render: (value) => numFormatterInt.format(+value),
                            sorter: (a, b) => a.organicWatchTimeHours - b.organicWatchTimeHours,
                        },
                        {
                            title: 'Organic Watch Time %',
                            dataIndex: 'organicWatchTimePercentage',
                            key: 'organicWatchTimePercentage',
                            // format as percentage
                            render: (value) => numFormatterPercent.format(+value),
                            sorter: (a, b) => a.organicWatchTimePercentage - b.organicWatchTimePercentage,
                        },
                        {
                            title: 'Audience Retention',
                            dataIndex: 'audienceRetention',
                            key: 'audienceRetention',
                            sorter: (a, b) => dayjs(a.audienceRetention, 'HH:mm:ss').diff(dayjs(b.audienceRetention, 'HH:mm:ss')),
                        },
                        {
                            title: 'Likes',
                            dataIndex: 'likes',
                            key: 'likes',
                            render: (value) => numFormatterInt.format(+value),
                            sorter: (a, b) => a.likes - b.likes,
                        },
                        {
                            title: 'Dislikes',
                            dataIndex: 'dislikes',
                            key: 'dislikes',
                            render: (value) => numFormatterInt.format(+value),
                            sorter: (a, b) => a.dislikes - b.dislikes,
                        },
                        {
                            title: 'Shares',
                            dataIndex: 'shares',
                            key: 'shares',
                            render: (value) => numFormatterInt.format(+value),
                            sorter: (a, b) => a.shares - b.shares,
                        },
                        {
                            title: 'Comments',
                            dataIndex: 'comments',
                            key: 'comments',
                            render: (value) => numFormatterInt.format(+value),
                            sorter: (a, b) => a.comments - b.comments,
                        },
                        {
                            title: 'Likes/ Dislikes Ratio',
                            dataIndex: 'likesDislikesRatio',
                            key: 'likesDislikesRatio',
                            render: (value) => numFormatterPercent.format(+value),
                            sorter: (a, b) => a.likesDislikesRatio - b.likesDislikesRatio,
                        },
                    ]}
                    // request={async () => {
                    //     const response = await axiosApiInstance.get(`/api/benchmarks/monthly`, {
                    //         params: {
                    //             year,
                    //             month
                    //         }
                    //     });
                    //     // console.log(response.data)
                    //     setMonthlyViews(response.data);
                    //     return {
                    //         data: response.data.data || [],
                    //         success: true,
                    //         total: response.data.data?.length,
                    //     };
                    // }}
                    // options={{
                    //     reload: true,
                    //     fullScreen: false,
                    //     setting: false,
                    //     density: false,
                    //     search: false,
                    // }}
                    summary={() => {
                        return (
                            <ProTable.Summary fixed="top">
                                <ProTable.Summary.Row style={{backgroundColor: '#e6f4ff'}}>
                                    <ProTable.Summary.Cell index={0}>
                                    </ProTable.Summary.Cell>
                                    <ProTable.Summary.Cell index={1}>
                                        <Typography.Text strong>Average</Typography.Text>
                                    </ProTable.Summary.Cell>
                                    <ProTable.Summary.Cell index={2}>
                                        <Typography.Text
                                            strong>{numFormatterInt.format(monthlyViews.avg.totalSubscribersLifetime)}</Typography.Text>
                                    </ProTable.Summary.Cell>
                                    <ProTable.Summary.Cell index={3}>
                                        <Typography.Text
                                            strong>{numFormatterInt.format(+monthlyViews.avg.subscribers)}</Typography.Text>
                                    </ProTable.Summary.Cell>
                                    <ProTable.Summary.Cell index={4}>
                                        <Typography.Text
                                            strong>{numFormatterInt.format(+monthlyViews.avg.totalViewsLifetime)}</Typography.Text>
                                    </ProTable.Summary.Cell>
                                    <ProTable.Summary.Cell index={5}>
                                        <Typography.Text
                                            strong>{numFormatterInt.format(+monthlyViews.avg.views)}</Typography.Text>
                                    </ProTable.Summary.Cell>
                                    <ProTable.Summary.Cell index={6}>
                                        <Typography.Text
                                            strong>{numFormatterInt.format(+monthlyViews.avg.organicViews)}</Typography.Text>
                                    </ProTable.Summary.Cell>
                                    <ProTable.Summary.Cell index={7}>
                                        <Typography.Text
                                            strong>{numFormatterPercent.format(+monthlyViews.avg.organicViewsPercentage)}</Typography.Text>
                                    </ProTable.Summary.Cell>
                                    <ProTable.Summary.Cell index={8}>
                                        <Typography.Text
                                            strong>{numFormatterInt.format(+monthlyViews.avg.totalWatchTimeHoursLifetime)}</Typography.Text>
                                    </ProTable.Summary.Cell>
                                    <ProTable.Summary.Cell index={9}>
                                        <Typography.Text
                                            strong>{numFormatterInt.format(+monthlyViews.avg.watchTimeHours)}</Typography.Text>
                                    </ProTable.Summary.Cell>
                                    <ProTable.Summary.Cell index={10}>
                                        <Typography.Text
                                            strong>{numFormatterInt.format(+monthlyViews.avg.organicWatchTimeHours)}</Typography.Text>
                                    </ProTable.Summary.Cell>
                                    <ProTable.Summary.Cell index={11}>
                                        <Typography.Text
                                            strong>{numFormatterPercent.format(+monthlyViews.avg.organicWatchTimePercentage)}</Typography.Text>
                                    </ProTable.Summary.Cell>
                                    <ProTable.Summary.Cell index={12}>
                                        <Typography.Text strong>{monthlyViews.avg.audienceRetention}</Typography.Text>
                                    </ProTable.Summary.Cell>
                                    <ProTable.Summary.Cell index={13}>
                                        <Typography.Text
                                            strong>{numFormatterInt.format(+monthlyViews.avg.likes)}</Typography.Text>
                                    </ProTable.Summary.Cell>
                                    <ProTable.Summary.Cell index={14}>
                                        <Typography.Text
                                            strong>{numFormatterInt.format(+monthlyViews.avg.dislikes)}</Typography.Text>
                                    </ProTable.Summary.Cell>
                                    <ProTable.Summary.Cell index={15}>
                                        <Typography.Text
                                            strong>{numFormatterInt.format(+monthlyViews.avg.shares)}</Typography.Text>
                                    </ProTable.Summary.Cell>
                                    <ProTable.Summary.Cell index={16}>
                                        <Typography.Text
                                            strong>{numFormatterInt.format(+monthlyViews.avg.comments)}</Typography.Text>
                                    </ProTable.Summary.Cell>
                                    <ProTable.Summary.Cell index={17}>
                                        <Typography.Text
                                            strong>{numFormatterPercent.format(+monthlyViews.avg.likesDislikesRatio)}</Typography.Text>
                                    </ProTable.Summary.Cell>
                                </ProTable.Summary.Row>
                            </ProTable.Summary>
                        )
                    }}
                    sticky={{offsetHeader: 54}}

                    pagination={false}
                />
            </Access>
        </div>
    );
};

export default MonthView;