import {ModalForm, ProFormCheckbox, ProFormGroup, ProFormList, ProFormSelect, ProFormText} from '@ant-design/pro-form';
import {Result} from 'antd';
import React from 'react';
import {useQuery} from '@tanstack/react-query';
import {useNavigate, useParams} from 'react-router-dom';
import axiosApiInstance from '../../../../api/axiosClient';
import {ProCard, ProSkeleton} from '@ant-design/pro-components';
import {API} from '../../../../common/constants';
import {useAtomValue} from "jotai";
import {artistsActionRefAtom} from "../ddex_atoms";

const REDIRECT_URL = '/music/artists';

type FormContentProps = {
    initialValues: API.MusicDistribution.Artist;
};

const EditArtistForm = () => {
    const navigate = useNavigate();
    const {key_name} = useParams();
    const artistsActionRef = useAtomValue(artistsActionRefAtom);

    const {data, status} = useQuery({
        queryKey: ['artist', key_name],
        queryFn: () =>
            axiosApiInstance
                .get(`/api/products/artists/${key_name}`)
                .then(({data}) => data)
                .catch((reason) => console.error(reason))
    });

    const FormContent = ({initialValues}: FormContentProps) => (
        <ProCard>
            <ProFormText
                label='Key Name'
                name='key_name'
                required
                rules={[
                    {
                        required: true
                    }
                ]}
                initialValue={initialValues.key_name}
            />
            <ProFormText name='spotify_id' label='Spotify ID' initialValue={initialValues.spotify_id}/>
            <ProFormText name='apple_music_id' label='Apple ID' initialValue={initialValues.apple_music_id}/>
            <ProFormList<API.MusicDistribution.Name>
                name='names'
                min={1}
                // actionRef={actionRef}
                colProps={{span: 24}}
                initialValue={initialValues.names}
                creatorButtonProps={{
                    position: 'bottom',
                    creatorButtonText: 'New Name'
                }}
            >
                {(f, index, action) => (
                    <ProFormGroup align='center' direction='horizontal' rowProps={{justify: 'start'}}>
                        <ProFormText
                            name='full_name'
                            label='Full Name'
                            placeholder={'Full Name'}
                            width='md'
                            rules={[
                                {
                                    required: true
                                }
                            ]}
                            required
                        />
                        <ProFormSelect
                            name='full_name_language'
                            label='Language'
                            mode='single'
                            width='xs'
                            // options={countryOptions}
                            options={[
                                {
                                    label: '🇬🇷',
                                    value: 'GR'
                                },
                                {
                                    label: '🇬🇧',
                                    value: 'EN'
                                }
                            ]}
                        />
                        <ProFormCheckbox key={'is_default'} name={'is_default'} label='Default?'/>
                        <ProFormCheckbox key={'is_stage_name'} name={'is_stage_name'} label='Stage Name?'/>
                    </ProFormGroup>
                )}
            </ProFormList>
        </ProCard>
    );

    const renderMap = {
        loading: <ProSkeleton type='list'/>,
        error: <Result title='Failed to load artist' status='error'/>,
        success: <FormContent initialValues={data}/>
    };
    return (
        <ModalForm<API.MusicDistribution.Artist>
            title='Edit artist'
            labelCol={{span: 3}}
            wrapperCol={{span: 21}}
            width={1000}
            open
            modalProps={{
                destroyOnClose: true,
                onCancel: () => navigate(REDIRECT_URL)
            }}
            onFinish={async (values) => {
                // navigate(REDIRECT_URL);
                return axiosApiInstance
                    .put(`/api/products/artists/`, values)
                    .then((response) => {
                        artistsActionRef?.current?.reload();
                        console.log(response)
                    })
                    .catch((reason) => console.log(reason))
                    .finally(() => navigate(REDIRECT_URL));
                // return true;
            }}
            // style={{ width: 1000 }}
        >
            {renderMap[status]}
        </ModalForm>
    );
};

export default EditArtistForm;
