import ProTable, { ProColumns } from '@ant-design/pro-table';
import axiosApiInstance from '../../../api/axiosClient';
import {
    Badge,
    Button,
    Input,
    message,
    Popover,
    Row,
    Space,
    Table,
    TableColumnsType,
    Tooltip,
    Typography
} from 'antd';
import {
    CheckCircleOutlined,
    CloudDownloadOutlined,
    DownloadOutlined,
    EditOutlined,
    PlusOutlined,
    SearchOutlined,
    StopOutlined,
    WarningOutlined
} from '@ant-design/icons';
import { DDEXPageContext, isModalOpenAtom } from './DDEXMainPage';
import { useAtom } from 'jotai';
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { releasesViewAtom } from './ddex_atoms';
import { useLocation, useNavigate } from 'react-router-dom';
import ExportReleasesExcel from './ExportReleasesExcel';
import ImportUpdateReleasesExcel from './ImportUpdateReleasesExcel';
import dayjs from 'dayjs';
import ProCard from '@ant-design/pro-card';
import UploadRelease from './UploadRelease';
import DeleteReleaseUpload from './DeleteReleaseUpload';
import ModifyRelease from './ModifyRelease';
import TakedownRelease from './TakedownRelease';
import { Link } from 'react-router-dom';

const UploadStatusBadgeMap = {
    uploaded: <Badge text='uploaded' color='green' />,
    modified: <Badge text='modified' color='blue' />,
    takedown: <Badge text='takedown' color='red' />
};

const PendingReleasesTable = () => {
    const [, setIsOpen] = useAtom(isModalOpenAtom);
    // const actionRef = useRef<ActionType>();
    // const releasesActionRef = useAtomValue(releasesActionRefAtom);
    const { releasesActionRef } = useContext(DDEXPageContext);
    const [releasesView, setReleasesView] = useAtom(releasesViewAtom);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => console.log('releases table rendered'));

    const expandedRowRender = (record: API.MusicDistribution.MusicRelease) => {
        const columns: TableColumnsType<API.MusicDistribution.ArtTrack> = [
            // {title: 'Track #', key: '#', render: (_dom, _entity, index) => <Badge count={index + 1}/>},
            { title: 'Track #', key: '#', render: (_dom, _entity, index) => index + 1 },
            { title: 'Title', dataIndex: 'title', key: 'title' },
            { title: 'ISRC', dataIndex: 'isrc', key: 'isrc' },
            // {title: 'Duration', dataIndex: 'duration', key: 'duration'},
            {
                title: 'Artist',
                dataIndex: 'track_artist',
                key: 'track_artist',
                render: (_text, { main_artists }) =>
                    // track_artist.names.find(({ is_default }) => is_default).full_name
                    main_artists.map((artist) => artist.key_name).join(', ')
            },
            {
                title: 'Featuring Artists',
                dataIndex: 'featuring_artists',
                key: 'featuring_artists',
                render: (_text, { featuring_artists }) =>
                    featuring_artists.map((artist) => artist.key_name).join(', ')
                // featuring_artists
                //     ?.map(({ names }) => names.find(({ is_default }) => is_default).full_name)
                //     .join(', ')
            },
            {
                title: '',
                render: (_dom, track) => {
                    return (
                        track.is_uploaded && (
                            <Tooltip title='Download'>
                                <Button
                                    type='link'
                                    icon={<DownloadOutlined />}
                                    onClick={async () => {
                                        try {
                                            const response = await axiosApiInstance.post(
                                                '/api/products/releases/generate-presigned-url-get',
                                                {
                                                    fileName: track.filename,
                                                    folderName: record.upc_ean
                                                }
                                            );

                                            const { presignedUrl } = response.data;

                                            window.open(presignedUrl, '_blank');
                                        } catch (err) {
                                            console.error('Error downloading file:', err);
                                            message.error('Error downloading file');
                                        }
                                    }}
                                />
                            </Tooltip>
                        )
                    );
                }
            }
        ];

        const data = record.art_tracks ?? [];
        return <Table columns={columns} dataSource={data} pagination={false} rowKey={'isrc'} />;
    };

    const filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
                placeholder={`Search Invoice`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => confirm()}
                style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type='primary'
                    onClick={() => {
                        confirm();
                    }}
                    icon={<SearchOutlined />}
                    size='small'
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => {
                        clearFilters && clearFilters(); // re ti kanoume
                        setSelectedKeys([]);
                        confirm();
                        close();
                    }}
                    size='small'
                    style={{ width: 90 }}
                >
                    Clear
                </Button>
            </Space>
        </div>
    );

    const filterIcon = (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    );

    const columns: ProColumns<API.MusicDistribution.MusicRelease>[] = [
        {
            title: '#',
            key: '#',
            search: false,
            render: (_dom, _entity, index) => <Badge count={index + 1} />
        },
        {
            title: 'Album Name',
            dataIndex: 'name',
            key: 'name',
            filterDropdown: filterDropdown,
            filterIcon: filterIcon,
            onFilter: (value, record) => record.name.toLowerCase().includes((value as string).toLowerCase()),
            sorter: (a, b) => a.name.localeCompare(b.name),
            render(_dom, entity, _index, _action, _schema) {
                return (
                    <Link to={`/music/${entity.upc_ean}/view`} state={{ background: location }}>
                        {entity.name}
                    </Link>
                );
            }
        },
        {
            title: 'UPC',
            dataIndex: 'upc_ean',
            key: 'upc',
            filterDropdown: filterDropdown,
            filterIcon: filterIcon,
            onFilter: (value, record) =>
                record.upc_ean.toLowerCase().includes((value as string).toLowerCase())
        },
        {
            title: 'Artist',
            dataIndex: 'main_artist',
            key: 'main_artist',
            renderText: (_text, { artists }) =>
                // main_artist.names.find(({ is_default }) => is_default).full_name
                artists.map((artist) => artist.key_name).join(', ')
        },
        {
            title: 'Label',
            dataIndex: 'label',
            key: 'label',
            renderText: (_text, { label }) => label.name
        },
        {
            title: 'Asset Label',
            dataIndex: 'asset_label',
            key: 'asset_label',
            renderText: (_text, { asset_label }) => asset_label.name
        },
        {
            title: 'Format',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: 'Release Date',
            dataIndex: 'digital_release_date',
            key: 'digital_release_date',
            valueType: 'date',
            sorter: (a, b) => dayjs(a.digital_release_date).diff(dayjs(b.digital_release_date))
        },
        {
            title: 'Upload Status',
            key: 'upload_status',
            hideInTable: releasesView !== 'verified',
            children: [
                {
                    title: 'YouTube',
                    width: 100,
                    align: 'center',
                    key: 'youtube_status',
                    render: (_text, record) => {
                        const log = record.release_upload_status_logs?.find(
                            (log) => log.platform === 'youtube'
                        );
                        return log ? (
                            <Tooltip title={dayjs(log.created_at).format('YYYY-MM-DD HH:mm')}>
                                {/* <Badge
                                    status={log.status === 'uploaded' ? 'success' : 'warning'}
                                    text={log.status}
                                /> */}
                                {UploadStatusBadgeMap[log.status]}
                            </Tooltip>
                        ) : (
                            <Badge status='warning' text={'pending'} />
                        );
                    }
                },
                {
                    title: 'Believe',
                    width: 100,
                    align: 'center',
                    key: 'believe_status',
                    render: (_text, record) => {
                        const log = record.release_upload_status_logs?.find(
                            (log) => log.platform === 'believe'
                        );
                        return log ? (
                            <Tooltip title={dayjs(log.created_at).format('YYYY-MM-DD HH:mm')}>
                                {/* <Badge
                                    status={log.status === 'uploaded' ? 'success' : 'warning'}
                                    text={log.status}
                                /> */}
                                {UploadStatusBadgeMap[log.status]}
                            </Tooltip>
                        ) : (
                            <Badge status='warning' text={'pending'} />
                        );
                    }
                },
                {
                    title: 'Spotify',
                    width: 100,
                    align: 'center',
                    key: 'spotify_status',
                    render: (_text, record) => {
                        const log = record.release_upload_status_logs?.find(
                            (log) => log.platform === 'spotify'
                        );
                        return log ? (
                            <Tooltip title={dayjs(log.created_at).format('YYYY-MM-DD HH:mm')}>
                                {/* <Badge
                                    status={log.status === 'uploaded' ? 'success' : 'warning'}
                                    text={log.status}
                                /> */}
                                {UploadStatusBadgeMap[log.status]}
                            </Tooltip>
                        ) : (
                            <Badge status='warning' text={'pending'} />
                        );
                    }
                }
            ]
        },
        {
            title: '',
            render: (_dom, record) => {
                return (
                    <>
                        {releasesView === 'pending' && (
                            <>
                                <Tooltip title='Edit'>
                                    <Button
                                        type='link'
                                        icon={<EditOutlined />}
                                        onClick={() =>
                                            navigate(`/music/${record.upc_ean}/edit`, {
                                                state: {
                                                    background: location
                                                }
                                            })
                                        }
                                    />
                                </Tooltip>
                                {record.has_warnings ? (
                                    <Popover
                                        title='Warnings'
                                        content={record.warnings.map((s) => (
                                            <Typography.Paragraph type='warning'>{s}</Typography.Paragraph>
                                        ))}
                                        placement='left'
                                    >
                                        <Button
                                            type='text'
                                            icon={
                                                <WarningOutlined
                                                    style={{
                                                        color: '#b3890c'
                                                    }}
                                                />
                                            }
                                        />
                                    </Popover>
                                ) : (
                                    <Tooltip title='Validate'>
                                        <Button
                                            type='link'
                                            icon={<CheckCircleOutlined />}
                                            onClick={async () => {
                                                await axiosApiInstance
                                                    .put(`/api/products/releases/${record.upc_ean}/verify`)
                                                    .catch((reason) => {
                                                        console.error(reason);
                                                    });
                                                releasesActionRef.current?.reload();
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            </>
                        )}
                        {releasesView === 'verified' && (
                            <Row>
                                <Tooltip title='Unverify'>
                                    <Button
                                        type='link'
                                        icon={<StopOutlined />}
                                        onClick={async () => {
                                            await axiosApiInstance
                                                .put(`/api/products/releases/${record.upc_ean}/unverify`)
                                                .catch((reason) => {
                                                    console.error(reason);
                                                });
                                            releasesActionRef.current?.reload();
                                        }}
                                    />
                                </Tooltip>
                                {/* <Tooltip title='Get believe excel'>
                                    <Button
                                        type='link'
                                        icon={<DownloadOutlined />}
                                        onClick={async () => {
                                            const response = await axiosApiInstance
                                                .get(`/api/products/releases/${record.upc_ean}/believe`, {
                                                    responseType: 'blob'
                                                })
                                                .then(({ data }) => data);
                                            const url = window.URL.createObjectURL(new Blob([response]));
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', `${record.upc_ean}.xlsx`);
                                            document.body.appendChild(link);
                                            link.click();
                                        }}
                                    />
                                </Tooltip> */}
                                {/* <Tooltip title='Get DDEX'>
                                    <Button
                                        type='link'
                                        icon={<CloudDownloadOutlined/>}
                                        onClick={async () => {
                                            const response = await axiosApiInstance
                                                .get(`/api/products/releases/${record.upc_ean}/ddex`, {
                                                    responseType: 'blob'
                                                })
                                                .then(({data}) => data);
                                            const url = window.URL.createObjectURL(new Blob([response]));
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', `${record.upc_ean}.xml`);
                                            document.body.appendChild(link);
                                            link.click();
                                        }}
                                    />
                                </Tooltip> */}
                                <UploadRelease upc_ean={record.upc_ean} key={record.upc_ean} />
                                <ModifyRelease upc_ean={record.upc_ean} key={record.upc_ean} />
                                <TakedownRelease upc_ean={record.upc_ean} key={record.upc_ean} />
                                {/*<DeleteReleaseUpload upc_ean={record.upc_ean} key={record.upc_ean}/>*/}
                            </Row>
                        )}
                        {/* {releasesView == 'pending' && <DeleteRelease id={record.upc_ean} key={record.upc_ean} />} */}
                    </>
                );
            }
        }
    ];
    return (
        <ProTable
            columns={columns}
            params={{
                with_label: '',
                with_artists: '',
                with_track_artists: '',
                is_pending: releasesView == 'pending'
            }}
            request={(params) => axiosApiInstance.get(`/api/products/releases/`, { params })}
            toolBarRender={() => [
                <Button type='primary' icon={<PlusOutlined />} onClick={() => setIsOpen((prev) => !prev)}>
                    Add
                </Button>,
                <ExportReleasesExcel />,
                releasesView === 'pending' ? <ImportUpdateReleasesExcel /> : null
            ]}
            rowKey={'upc_ean'}
            search={false}
            headerTitle={<ProCard title='Header' />}
            expandable={{ expandedRowRender }}
            actionRef={releasesActionRef}
            // onRow={(record, index) => ({
            //     onClick: (e) => navigate(`/music/${record.upc_ean}/edit`)
            // })}
            toolbar={{
                menu: {
                    type: 'tab',
                    activeKey: releasesView,
                    items: [
                        {
                            key: 'pending',
                            label: 'Pending'
                        },
                        {
                            key: 'verified',
                            label: 'Verified'
                        }
                    ],
                    onChange: (key) => {
                        setReleasesView(key as string);
                    }
                }
            }}
        />
    );
};

export default PendingReleasesTable;
