import {ModalForm, ProFormText} from '@ant-design/pro-form';
import {Result} from 'antd';
import React from 'react';
import {useQuery} from '@tanstack/react-query';
import {useNavigate, useParams} from 'react-router-dom';
import axiosApiInstance from '../../../../api/axiosClient';
import {ProCard, ProSkeleton} from '@ant-design/pro-components';
import {API} from '../../../../common/constants';
import {useAtomValue} from "jotai";
import {queryClientAtom} from "jotai-tanstack-query";

const REDIRECT_URL = '/music/asset_labels';

type FormContentProps = {
    initialValues: API.MusicDistribution.Label;
};

const EditAssetLabelForm = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const queryClient = useAtomValue(queryClientAtom);

    const {data, status} = useQuery({
        queryKey: ['asset_label', id],
        queryFn: () =>
            axiosApiInstance
                .get(`/api/products/asset_labels/${id}`)
                .then(({data}) => data)
                .catch((reason) => console.error(reason))
    });

    const FormContent = ({initialValues}: FormContentProps) => (
        <ProCard>
            <ProFormText hidden name='ID' initialValue={initialValues.ID}/>
            <ProFormText name='name' label='Name' initialValue={initialValues.name}/>
            <ProFormText name='p_line' label='(P) Line' initialValue={initialValues.p_line}/>
            <ProFormText name='c_line' label='(C) Line' initialValue={initialValues.c_line}/>
        </ProCard>
    );

    const renderMap = {
        loading: <ProSkeleton type='list'/>,
        error: <Result title='Failed to load label' status='error'/>,
        success: <FormContent initialValues={data}/>
    };
    return (
        <ModalForm<API.MusicDistribution.Label>
            title='Edit asset label'
            labelCol={{span: 3}}
            wrapperCol={{span: 21}}
            width={1000}
            open
            modalProps={{
                destroyOnClose: true,
                onCancel: () => navigate(REDIRECT_URL)
            }}
            onFinish={async (values) => {
                // navigate(REDIRECT_URL);
                axiosApiInstance
                    .put(`/api/products/asset_labels/`, values)
                    .then((response) => {
                        // console.log(response)
                        queryClient.invalidateQueries({queryKey: ['asset_labels']});
                    })
                    .catch((reason) => console.log(reason))
                    .finally(() => navigate(REDIRECT_URL));
                return true;
            }}
            // style={{ width: 1000 }}
        >
            {renderMap[status]}
        </ModalForm>
    );
};

export default EditAssetLabelForm;
