import {
    Badge,
    Button,
    Divider,
    Modal,
    Popover,
    Segmented,
    Space,
    Table,
    Tag,
    Tooltip,
    Typography
} from 'antd';
import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { json, Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import axiosApiInstance from '../../../api/axiosClient';
import ProDescriptions from '@ant-design/pro-descriptions';
import { ProFormGroup, ProFormItem } from '@ant-design/pro-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompactDisc, faMusic, faB } from '@fortawesome/free-solid-svg-icons';
import { faSpotify, faYoutube } from '@fortawesome/free-brands-svg-icons';
import ProCard from '@ant-design/pro-card';
import { ColumnsType } from 'antd/es/table';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { atom, useAtom } from 'jotai';
import { DownloadOutlined, FileOutlined, UploadOutlined } from '@ant-design/icons';
import XMLViewer from 'react-xml-viewer';
import dayjs from 'dayjs';

const ICON_WIDTH = 40;

const ReleaseTypeTagMap = {
    ALBUM: <Tag color='red'>Album</Tag>,
    SINGLE: <Tag color='green'>Single</Tag>,
    EP: <Tag color='blue'>EP</Tag>
};

const UploadStatusesBadgeMap = {
    uploaded: <Badge status='success' text='Release' />,
    modified: <Badge color='blue' text='Update' />,
    takedown: <Badge status='error' text='Takedown' />
};

const UploadStatusesTagMap = {
    uploaded: <Tag color='green'>Release</Tag>,
    modified: <Tag color='blue'>Update</Tag>,
    takedown: <Tag color='red'>Takedown</Tag>
};

const selectedPlatform = atom('youtube');

const formatDate = (dateString: string): string => dayjs(dateString).format('DD-MM-YYYY HH:mm:ss');

const formatTitle = ({ name, subtitle }: API.MusicDistribution.MusicRelease): string => {
    var title = name;
    if (subtitle !== '') {
        title = `${title} (${subtitle})`;
    }
    return title;
};

const formatGenre = ({ genre, sub_genre }: API.MusicDistribution.MusicRelease): string => {
    var str = genre;
    if (sub_genre !== '') {
        str = `${str} (${sub_genre})`;
    }
    return str;
};

const renderArtists = (value: API.MusicDistribution.Artist[]): string =>
    value.map((a) => a.key_name).join(', ') || '-';

const trackTableColumns: ColumnsType<API.MusicDistribution.ArtTrack> = [
    {
        key: 'vol_no',
        dataIndex: 'volume_number',
        title: (
            <Tooltip title='Volume Number'>
                <FontAwesomeIcon icon={faCompactDisc} />
            </Tooltip>
        )
    },
    {
        key: 'track_no',
        dataIndex: 'track_number',
        title: (
            <Tooltip title='Track Number'>
                <FontAwesomeIcon icon={faMusic} />
            </Tooltip>
        )
    },
    {
        key: 'isrc',
        dataIndex: 'isrc',
        title: 'ISRC'
    },
    {
        key: 'title',
        dataIndex: 'title',
        title: 'Title'
    },
    {
        key: 'main_artists',
        dataIndex: 'main_artists',
        title: 'Main Artist(s)',
        render: renderArtists
    },
    {
        key: 'featuring_artists',
        dataIndex: 'featuring_artists',
        title: 'Featuring',
        render: renderArtists
    },
    {
        key: 'authors',
        dataIndex: 'authors',
        title: 'Author(s)',
        render: renderArtists
    },
    {
        key: 'composers',
        dataIndex: 'composers',
        title: 'Composer(s)',
        render: renderArtists
    }
];

const uploadHistoryColumns: ColumnsType<API.MusicDistribution.UploadStatusLogs> = [
    {
        key: 'created_at',
        dataIndex: 'created_at',
        title: 'Timestamp',
        render: (value) => formatDate(value)
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: 'Action',
        render: (value) => UploadStatusesBadgeMap[value]
    },
    {
        key: 'xml',
        dataIndex: 'message',
        title: '',
        render: (value, record, index) => (
            <Popover
                trigger='click'
                placement='left'
                content={
                    <ProCard
                        style={{
                            width: 1000,
                            height: 600,
                            overflowX: 'scroll',
                            overflowY: 'scroll',
                            paddingLeft: 5,
                            paddingRight: 5,
                            paddingTop: 15,
                            paddingBottom: 15
                        }}
                    >
                        <ProCard
                            title={`${record.upc_ean}`}
                            subTitle={
                                <Space>
                                    {formatDate(record.created_at)} {UploadStatusesTagMap[record.status]}
                                </Space>
                            }
                            extra={
                                <Tooltip title='Download XML'>
                                    <Button
                                        type='link'
                                        icon={<DownloadOutlined />}
                                        onClick={() => {
                                            const url = window.URL.createObjectURL(
                                                new Blob([record.message])
                                            );
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute(
                                                'download',
                                                `${record.upc_ean}_${record.created_at}_${record.status}.xml`
                                            );
                                            document.body.appendChild(link);
                                            link.click();
                                        }}
                                    />
                                </Tooltip>
                            }
                            bordered
                            headerBordered
                        >
                            <XMLViewer xml={value as string} collapsible />
                        </ProCard>
                    </ProCard>
                }
            >
                <Tooltip title='View Metadata'>
                    <Button icon={<FileOutlined />} type='link' />
                </Tooltip>
            </Popover>
        )
    }
];

const UploadPlatformsSegmented = () => {
    const [, setPlatform] = useAtom(selectedPlatform);
    return (
        <Segmented
            options={[
                {
                    value: 'youtube',
                    icon: <FontAwesomeIcon icon={faYoutube} color='red' width={ICON_WIDTH} />,
                    title: 'youtube'
                },
                {
                    value: 'spotify',
                    icon: <FontAwesomeIcon icon={faSpotify} color='#1DB954' width={ICON_WIDTH} />
                },
                {
                    value: 'believe',
                    icon: <FontAwesomeIcon icon={faB} color='#000035' width={ICON_WIDTH} />
                }
            ]}
            onChange={(value) => setPlatform(value as string)}
            onResize={undefined}
            onResizeCapture={undefined}
        />
    );
};

const ViewReleaseModal = () => {
    const { upc_ean } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [platform, setPlatform] = useAtom(selectedPlatform);

    useEffect(() => {
        return () => {
            // cleanup: reset selected platform after close
            setPlatform('youtube');
        };
    }, []);

    const { data } = useQuery({
        queryKey: [
            {
                path: ['/api/products/releases', upc_ean],
                target: upc_ean
            }
        ],
        queryFn: ({ queryKey }) =>
            axiosApiInstance
                .get<API.MusicDistribution.MusicRelease>(`/api/products/releases/${queryKey[0].target}`, {
                    params: {
                        with_label: true,
                        with_artists: true,
                        with_track_artists: true
                    }
                })
                .then(({ data }) => data),
        suspense: true
    });

    const { data: uploadLogsData } = useQuery({
        queryKey: [
            {
                path: ['/api/products/releases', upc_ean, 'records'],
                target: upc_ean
            }
        ],
        queryFn: ({ queryKey }) =>
            axiosApiInstance
                .get<API.MusicDistribution.ReleaseUploadStatusLog[]>(
                    `/api/products/releases/${queryKey[0].target}/records`
                )
                .then(({ data }) => data),
        suspense: true
    });

    return (
        <Modal open title={data.name} width={1000} onCancel={() => navigate('/music')} destroyOnClose>
            {/* <p>{JSON.stringify(data)}</p> */}
            <ProDescriptions column={8}>
                <ProDescriptions.Item span={8}>
                    <Divider />
                </ProDescriptions.Item>
                <ProDescriptions.Item label='UPC' span={2}>
                    {data.upc_ean}
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Release Type' span={2}>
                    {ReleaseTypeTagMap[data.type]}
                </ProDescriptions.Item>
                <ProDescriptions.Item span={4} />
                <ProDescriptions.Item label='Original Release Date' valueType='date' span={2}>
                    {data.original_release_date}
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Digital Release Date' valueType='date' span={2}>
                    {data.digital_release_date}
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Genre' valueType='text' span={4}>
                    {formatGenre(data)}
                </ProDescriptions.Item>
                <ProDescriptions.Item span={8}>
                    <Divider />
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Label' span={3}>
                    <Popover
                        content={
                            <div style={{ width: 300 }}>
                                <ProDescriptions column={4}>
                                    <ProDescriptions.Item span={4} label='P Line'>
                                        {data.label.p_line}
                                    </ProDescriptions.Item>
                                    <ProDescriptions.Item span={4} label='C Line'>
                                        {data.label.c_line}
                                    </ProDescriptions.Item>
                                </ProDescriptions>
                            </div>
                        }
                    >
                        <Tag color='blue'>{data.label.name}</Tag>
                    </Popover>
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Main Artists' span={5}>
                    {data.artists.map((a) => a.key_name).join(', ')}
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Asset Label' span={3}>
                    <Popover
                        content={
                            <div style={{ width: 300 }}>
                                <ProDescriptions column={4}>
                                    <ProDescriptions.Item span={4} label='P Line'>
                                        {data.asset_label.p_line}
                                    </ProDescriptions.Item>
                                    <ProDescriptions.Item span={4} label='C Line'>
                                        {data.asset_label.c_line}
                                    </ProDescriptions.Item>
                                </ProDescriptions>
                            </div>
                        }
                    >
                        <Tag color='red'>{data.asset_label.name}</Tag>
                    </Popover>
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Featured Artists' span={5}>
                    {data.featuring_artists.map((a) => a.key_name).join(', ') || 'N/A'}
                </ProDescriptions.Item>
                <ProDescriptions.Item span={8}>
                    <Divider />
                </ProDescriptions.Item>
            </ProDescriptions>
            <ProCard colSpan={24} split='vertical'>
                <ProCard colSpan={14} title='Tracks' extra={<Button ghost />}>
                    <div
                        style={{
                            height: 400,
                            overflowY: 'scroll',
                            overflowX: 'scroll'
                        }}
                    >
                        <Table
                            columns={trackTableColumns}
                            dataSource={data.art_tracks}
                            pagination={{ pageSize: 20 }}
                        />
                    </div>
                </ProCard>
                <ProCard colSpan={10} title='Upload History' extra={<UploadPlatformsSegmented />}>
                    <div
                        style={{
                            height: 400,
                            overflowY: 'scroll',
                            overflowX: 'scroll'
                        }}
                    >
                        <Table
                            columns={uploadHistoryColumns}
                            dataSource={uploadLogsData.filter((log) => log.platform === platform)}
                        />
                    </div>
                </ProCard>
            </ProCard>
        </Modal>
    );
};

export default ViewReleaseModal;
