import ProTable, {ProColumns} from '@ant-design/pro-table';
import React from 'react';
import axiosApiInstance from '../../../../api/axiosClient';
import {Button, Form} from 'antd';
import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import {useLocation, useNavigate} from 'react-router-dom';
import {ModalForm, ProFormSelect, ProFormText} from '@ant-design/pro-form';
import {atom, useAtom, useAtomValue} from "jotai";
import {assetLabelsAtom, labelsActionRefAtom} from "../ddex_atoms";

const isAddNewLabelModalOpen = atom(false);

const NewLabel = () => {
    const [form] = Form.useForm<API.DDEX.Label>();
    const labelsActionRef = useAtomValue(labelsActionRefAtom);
    const [isOpen, setIsOpen] = useAtom(isAddNewLabelModalOpen);
    const {data: assetLabels, status: statusAssetLabels} = useAtomValue(assetLabelsAtom);

    return (
        <ModalForm<API.DDEX.Label>
            title='Add new label'
            width={1000}
            form={form}
            labelCol={{span: 3}}
            wrapperCol={{span: 21}}
            trigger={
                <Button
                    type='primary'
                    icon={<PlusOutlined/>}
                    style={{width: '100%'}}
                    onClick={() => setIsOpen(true)}
                >
                    Add
                </Button>
            }
            modalProps={{
                destroyOnClose: true,
                onCancel: () => setIsOpen(false)
            }}
            onFinish={async (values) => {
                await axiosApiInstance.post('/api/products/labels/', values);
                // stepFormRef.current?.resetFields(['label_id']);
                labelsActionRef?.current?.reload();
                setIsOpen(false);
                return true;
            }}
            open={isOpen}
        >
            <ProFormText key={'name'} name={'name'} label='Name'/>
            <ProFormText key={'p_line'} name={'p_line'} label='(P) Line'/>
            <ProFormText key={'c_line'} name={'c_line'} label='(C) Line'/>
            <ProFormSelect name={'asset_label_id'} label='Asset Label' fieldProps={{loading: statusAssetLabels === 'pending'}}
                           options={assetLabels?.map((label) => ({label: label.name, value: label.ID}))}
            />
        </ModalForm>
    );
};

const LabelsTable = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const labelsActionRef = useAtomValue(labelsActionRefAtom);

    const columns: ProColumns<API.MusicDistribution.Label>[] = [
        {
            title: 'id',
            key: 'id',
            dataIndex: 'ID',
            hideInSearch: true,
            hideInForm: true
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            hideInSearch: true,
            hideInForm: true
        },
        {
            title: '(P) Line',
            key: 'p_line',
            dataIndex: 'p_line',
            hideInSearch: true,
            hideInForm: true
        },
        {
            title: '(C) Line',
            key: 'c_line',
            dataIndex: 'c_line',
            hideInSearch: true,
            hideInForm: true
        },
        {
            title: '',
            key: 'actions',
            search: false,
            render(_dom, entity) {
                return (
                    <>
                        <Button
                            type='link'
                            icon={<EditOutlined/>}
                            onClick={() =>
                                navigate(`/music/labels/${entity.ID}/edit`, {
                                    state: {background: location}
                                })
                            }
                        />
                        <Button
                            type='link'
                            icon={<DeleteOutlined/>}
                            danger
                            onClick={() => {
                                navigate(`/music/labels/${entity.ID}/delete`, {
                                    state: {background: location}
                                });
                            }}
                        />
                    </>
                );
            }
        }
    ];
    return (
        <ProTable
            columns={columns}
            actionRef={labelsActionRef}
            search={false}
            request={(params) => axiosApiInstance.get(`/api/products/labels/`)}
            rowKey='ID'
            toolBarRender={() => [<NewLabel/>]}
        />
    );
};

export default LabelsTable;
