import React, { Component } from "react";
import "./index.css";
import {
  AutoComplete,
  Col,
  Input,
  message,
  Row,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import { ContractListRow } from "../../../common/models";
import { Link, Navigate } from "react-router-dom";
import { postRequest } from "../../../api/postRequest";
import { PageContainer } from "@ant-design/pro-components";

export interface IIndexProps {
  //Here we pass the Props Interface
  api_call: string;
}

export interface IIndexState {
  //here we pass the State Interface
  currentPage: number;
  rows: number; // number of rows fetched
  isLoading: boolean; // while loading
  contract: number; //Current contract
  redirect: boolean;
  searched: string;

  data: any; // Fetched data
  filteredData: any; // data after filter (initially same as fetched data)
}

const PAGE_SIZE = 10;

//class ComponentName Component<PropsInterface, StateInterface>
class Contracts extends Component<IIndexProps, IIndexState> {
  //Component State
  constructor(props: IIndexProps) {
    super(props);

    this.state = {
      currentPage: 1,
      rows: 0, // number of rows fetched
      isLoading: true, // while loading
      contract: 0, //Current contract
      searched: "",

      data: [], // Fetched data
      filteredData: [], // data after filter (initially same as fetched data)
      redirect: false,
    };
  }

  //Add style here
  style = {};

  getFunc = async () => {
    try {
      const response = await postRequest("/api/contracts/getall");
      console.log(response.data);
      const contracts = response.data || [];

      this.setState(
        {
          data: contracts,
          filteredData: contracts,
          rows: contracts.length,
        },
        async () => {
          this.setState({ isLoading: false });
        }
      );
    } catch (error) {
      //   this.setState({ isLoading: true });
      console.log("Error on GET :" + error);
      message.warning("Empty elements returned! 0 contracts found.");
      this.setState(
        {
          data: [],
          filteredData: [],
          rows: 0,
        },
        async () => {
          this.setState({ isLoading: false });
        }
      );
    }
  };

  // columns: ColumnsType<any> | undefined;

  getUniqueValues(field: string): { text: string; value: string }[] {
    let distinct = Array.from(
      new Set<string>(
        this.state.data.arr.map((record: any) => record[field])
      ).values()
    ).sort();
    let filters: { text: string; value: string }[] = [];
    distinct.forEach((item) => {
      filters.push({ text: item, value: item });
    });

    // console.log(filters);

    return filters;
  }

  // Before the component mounts, we initialise our state
  componentWillMount() {}

  // After the component did mount, we set the state.
  componentDidMount() {
    this.getFunc();
  }

  setContract(id: number) {
    if (id !== this.state.contract) {
      this.setState({ contract: id }, () => {
        this.setState({ redirect: true });
      });
      //  console.log('set asset', id);
    }
  }

  handleSearchInput(text: string) {
    // console.log('handler', text);

    this.setState({ searched: text }, () => {
      this.setState({
        filteredData: this.state.data.filter(
          (customer: ContractListRow) =>
            String(customer.id)
              .toLowerCase()
              .includes(this.state.searched.toLowerCase()) ||
            customer.full_name
              .toLowerCase()
              .includes(this.state.searched.toLowerCase()) ||
            String(customer.customer_id)
              .toLowerCase()
              .includes(this.state.searched.toLowerCase())
        ),
      });
    });
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={"/crm/contracts/view/" + this.state.contract} />;
    }

    const columns = [
      {
        title: "Contract ID",
        dataIndex: "id",
        key: "id",
        sorter: (a: ContractListRow, b: ContractListRow) => a.id - b.id,
      },
      {
        title: "Start Date",
        dataIndex: "start_date",
        key: "start_date",
        // render: (text: string) => { return new Date(text.substr(0, 4) + '-' + text.substr(4, 2) + '-' + text.substr(6, 2)).toLocaleDateString('el') },
        sorter: (a: ContractListRow, b: ContractListRow) =>
          a.start_date.localeCompare(b.start_date),
      },
      {
        title: "End Date",
        dataIndex: "end_date",
        key: "end_date",
        // render: (text: string) => { return new Date(text.substr(0, 4) + '-' + text.substr(4, 2) + '-' + text.substr(6, 2)).toLocaleDateString('el') },
        sorter: (a: ContractListRow, b: ContractListRow) =>
          a.end_date.localeCompare(b.end_date),
      },
      {
        title: "Customer ID",
        dataIndex: "customer_id",
        key: "customer_id",
        sorter: (a: ContractListRow, b: ContractListRow) =>
          a.customer_id - b.customer_id,
      },
      {
        title: "Customer Name",
        dataIndex: "full_name",
        key: "full_name",
        sorter: (a: ContractListRow, b: ContractListRow) =>
          a.full_name.localeCompare(b.full_name),
      },
      {
        title: "# Linked Assets",
        dataIndex: "num_assets",
        key: "num_assets",
        sorter: (a: ContractListRow, b: ContractListRow) =>
          a.num_assets - b.num_assets,
      },
      // {
      //    title: 'Autorenew',
      //    dataIndex: 'autorenew',
      //    key: 'autorenew',
      //    render: (text: string) => text === "1" ? <div style={{ color: 'green' }}>{"true"}</div> : <div style={{ color: 'green' }}>{"false"}</div>
      // },
      // {
      //    title: 'Admin Fees',
      //    dataIndex: 'admin_fees',
      //    key: 'admin_fees',
      // },
      // {
      //    title: 'Admin Fees %',
      //    dataIndex: 'admin_fees_percentage',
      //    key: 'admin_fees_percentage',
      // },
      // {
      //    title: 'Client Commission %',
      //    dataIndex: 'fixed_commission_percentage',
      //    key: 'fixed_commission_percentage',
      // },
      // {
      //    title: 'Contract Vault Id',
      //    dataIndex: 'contract_vault_id',
      //    key: 'contract_vault_id',
      // },
    ];

    return (
      <>
        <div className="Index" style={this.style}>
          <PageContainer>
            <Row gutter={0} id="contracts-search">
              {/* <Col span={this.state.asset === '0' ? 23 : 12}> */}
              <Col span={23}>
                <Spin
                  spinning={this.state.isLoading}
                  style={{ height: "100vh" }}
                >
                  {!this.state.isLoading && (
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <AutoComplete
                        style={{ width: "100%" }}
                        // onSelect={(id: any) => {
                        //     this.setContract(parseInt(id));
                        // }}
                        onChange={(text: string) => {
                          this.handleSearchInput(text);
                        }}
                        options={this.state.filteredData.map(
                          (contract: ContractListRow) => {
                            return {
                              label: (
                                <Link to={"/crm/contracts/view/" + contract.id}>
                                  <Typography.Text>
                                    {contract.id +
                                      " / " +
                                      contract.full_name +
                                      " (" +
                                      contract.customer_id +
                                      ")"}
                                  </Typography.Text>
                                </Link>
                              ),
                              // label: contract.id + ' / ' + contract.full_name + ' (' + contract.customer_id + ')',
                              value: contract.id,
                            };
                          }
                        )}
                      >
                        <Input.Search
                          size="large"
                          placeholder="Search..."
                          allowClear
                        />
                      </AutoComplete>

                      {/* <br /> */}

                      <Table
                        style={{ width: "100%" }}
                        columns={columns}
                        dataSource={this.state.data}
                        onRow={(record: ContractListRow, rowIndex: any) => {
                          return {
                            onClick: (event: any) => {
                              this.setContract(record.id);
                            }, // click row
                            onDoubleClick: (event: any) => {}, // double click row
                            onContextMenu: (event: any) => {}, // right button click row
                            onMouseEnter: (event: any) => {}, // mouse enter row
                            onMouseLeave: (event: any) => {}, // mouse leave row
                          };
                        }}
                        pagination={{
                          showSizeChanger: false,
                          pageSize: PAGE_SIZE,
                        }}
                        rowClassName={"clickable-row"}
                      />
                    </Space>
                  )}
                </Spin>
              </Col>
            </Row>
          </PageContainer>
        </div>
      </>
    );
  }
}

export default Contracts;
