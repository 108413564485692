import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Index from './pages/Index';
import { MyLayout } from './components/MyLayout';
import PrivateRoutes from './components/PrivateRoutes';
import { UserLogin } from './pages/UserLogin';
import NoFoundPage from './pages/404';
import Customers from './CRM/pages/Customers';
import Assets from './CRM/pages/Assets';
import Contracts from './CRM/pages/Contracts';
import Customer from './CRM/pages/ExtendedCustomer';
import ExtendedAsset from './CRM/pages/ExtendedAsset';
import ExtendedContract from './CRM/pages/ExtendedContract';
import NewCustomer from './CRM/pages/NewCustomer';
import NewAsset from './CRM/pages/NewAsset';
import NewContract from './CRM/pages/NewContract';
import EditCustomer from './CRM/pages/EditCustomer';
import EditAsset from './CRM/pages/EditAsset';
import EditContract from './CRM/pages/EditContract';
import PaymentsHistory from './ERP/pages/PaymentsHistory';
import Rates from './ERP/pages/Rates';
import Unmatched from './ERP/pages/Unmatched';
import Affiliates from './ERP/pages/Affiliates';
import BillableCycleStatus from './ERP/pages/BillableCycleStatus';
import PaymentCycles from './ERP/pages/PaymentCycles';
import NewBillableCycle from './ERP/pages/NewBillableCycle';
import FileManager from './ERP/pages/FileManager';
import PlatformProductKeys from './ERP/pages/PlatformProductKeys';
import WhatIfReportingV2 from './REPORTING/pages/WhatIfReportingV2';
import SmartReports from './REPORTING/pages/SmartReports';
import BrandAccountReporting from './REPORTING/pages/BrandAccountReporting';
import AnalyticsQueryEngine from './REPORTING/pages/AnalyticsQueryEngine';
import VideoComparison from './REPORTING/pages/VideoComparison';
import DashboardReportingV2 from './REPORTING/pages/DashboardReportingV2';
import ReportingChartsEngine from './REPORTING/pages/ReportingChartsEngine';
import AdminDashboard from './pages/Admin/AdminDashboard';
import './App.css';
import { AccessCRM } from './components/AccessCRM';
import { AccessERP } from './components/AccessERP';
import { AccessReporting } from './components/AccessReporting';
import { AccessAdmin } from './components/AccessAdmin';
import { AccessSocial } from './components/AccessSocial';
import CreatorsLeads from './CRM/pages/CreatorsLeads/CreatorsLeads';
import React, { Suspense, useContext } from 'react';
import { GlobalStateContext } from './context/GlobalContext';
import { PageLoading } from '@ant-design/pro-layout';
import RedirectCycleIndex from './ERP/pages/PaymentCyclePanel/RedirectCycleIndex';
import PaymentCyclePanel from './ERP/pages/PaymentCyclePanel/PaymentCyclePanel';
import PaymentCycleFiles from './ERP/components/PaymentCycleFiles';
import PaymentCycleUnmatchedFiles from './ERP/components/PaymentCycleUnmatchedFiles';
import PaymentCycleUnmatchedKeys from './ERP/components/PaymentCycleUnmatchedKeys';
import PaymentCycleCustomers from './ERP/components/PaymentCycleCustomers';
import PaymentCycleAssets from './ERP/components/PaymentCycleAssets';
import PaymentCycleBalances from './ERP/components/PaymentCycleBalances';
import PaymentCycleRates from './ERP/components/PaymentCycleRates';
import PaymentCycleResultsAnalytics from './ERP/components/PaymentCycleResultsAnalytics';
import PaymentCycleCustomerReports from './ERP/components/PaymentCycleCustomerReports';
import PaymentCycleVideoReportsFileVault from './ERP/components/PaymentCycleVideoReportsFileVault';
import PaymentCycleRawDataFileVault from './ERP/components/PaymentCycleRawDataFileVault';
import CreatorsLeadsSearch from './CRM/pages/CreatorsLeads/CreatorsLeadsSearch';
import CreatorsLeadsSearchReportInstagram from './CRM/pages/CreatorsLeads/CreatorsLeadsSearchReportInstagram';
import CreatorsLeadsSearchReportYoutube from './CRM/pages/CreatorsLeads/CreatorsLeadsSearchReportYoutube';
import CreatorsLeadsSearchReportTiktok from './CRM/pages/CreatorsLeads/CreatorsLeadsSearchReportTiktok';
import CreatorLeadViewEdit from './CRM/pages/CreatorsLeads/CreatorLeadViewEdit';
import MetadataConverter from './TOOLS/MetadataConverter/MetadataConverter';
import CreatorLeadCreate from './CRM/pages/CreatorsLeads/CreatorLeadCreate';
import VideoStatsReporting from './REPORTING/pages/VideoStatsReporting';
import YouTubeBenchmarks from './REPORTING/pages/Benchmarks/YouTube/YouTubeBenchmarks';
import TierMetrics from './REPORTING/pages/Tiers/TierMetrics';
import TikTokBenchmarks from './REPORTING/pages/Benchmarks/TikTok/TikTokBenchmarks';
import ConnectionsPage from './CRM/pages/Connections/Connections';
import NewConnectionModal from './CRM/pages/Connections/NewConnectionModal';
import ViewConnectionModal from './CRM/pages/Connections/ViewConnectionModal';
import DeleteConnectionModal from './CRM/pages/Connections/DeleteConnectionModal';
import EditConnectionModal from './CRM/pages/Connections/EditConnectionModal';
import { Modal, Spin } from 'antd';
import CallModal from './CRM/pages/Connections/CallModal';
import CreatorCreate from './CRM/pages/Creators/CreatorCreate';
import CreatorViewEdit from './CRM/pages/Creators/CreatorViewEdit';
// import RevenueBrackets, { DeleteRevenueBracketEntry } from './CRM/pages/RevenueBrackets/RevenueBrackets';
import { AccessTesting } from './components/AccessTesting';
import DDEXMainPage, { PageContextProvider } from './CRM/pages/DDEXPage/DDEXMainPage';
import ArtTracksArtistsModal from './CRM/pages/DDEXPage/ArtTracksArtistsModal';
import ReleaseUpdateStepForm from './CRM/pages/DDEXPage/ReleaseUpdateStepForm';
import CreatorsWrapper from './CRM/pages/Creators/CreatorsWrapper';
import ArtistsTable from './CRM/pages/DDEXPage/ArtistsList/ArtistsTable';
import EditArtistForm from './CRM/pages/DDEXPage/ArtistsList/EditArtistForm';
import LabelsTable from './CRM/pages/DDEXPage/LabelsList/LabelsTable';
import EditLabelForm from './CRM/pages/DDEXPage/LabelsList/EditLabelForm';
import AssetLabelsTable from './CRM/pages/DDEXPage/AssetLabel/AssetLabelsTable';
import DeleteArtistForm from './CRM/pages/DDEXPage/ArtistsList/DeleteArtistForm';
import DeleteLabelForm from './CRM/pages/DDEXPage/LabelsList/DeleteLabelForm';
import { useAtomValue } from 'jotai';
// import { queryClientAtom } from 'jotai-tanstack-query';
import { ReactQueryDevtools } from 'react-query/devtools';
// import { QueryClientProvider } from 'react-query';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import EditAssetLabelForm from './CRM/pages/DDEXPage/AssetLabel/EditAssetLabelForm';
import DeleteAssetLabelForm from './CRM/pages/DDEXPage/AssetLabel/DeleteAssetLabelForm';
import ViewReleaseModal from './CRM/pages/DDEXPage/ViewReleaseModal';
import ModalLoading from './CRM/pages/DDEXPage/ModalLoading';
import AccessMusic from './components/AccessMusic';
import AccessMusicAssetLabels from './components/AccessMusicAssetLabel';

function App() {
    const location = useLocation();
    const background = location.state && location.state.background;
    const { authorizing } = useContext(GlobalStateContext);
    // console.log(background)
    // const queryClient = useAtomValue(queryClientAtom);
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnMount: false,
                refetchOnWindowFocus: false,
                staleTime: 10 * 60 * 1000
            }
        }
    });
    // queryClient.setDefaultOptions({
    //     queries: {
    //         refetchOnMount: false,
    //         refetchOnWindowFocus: false,
    //         staleTime: 10 * 60 * 1000
    //     }
    // });

    if (authorizing) {
        return <PageLoading />;
    }
    return (
        <QueryClientProvider client={queryClient}>
            <div className='App'>
                <Routes location={background || location}>
                    <Route element={<PrivateRoutes />}>
                        <Route element={<MyLayout />}>
                            {/*<Route path='/' element={<Homepage/>}/>*/}
                            {/*<Route index element={<Home />} />*/}
                            {/*<Route path='/' element={<Index/>}/>*/}
                            <Route path='/' element={<Navigate to={'/crm/assets/list'} />} />
                            <Route path='home' element={<Index />} />

                            <Route path='crm' element={<AccessCRM />}>
                                <Route index element={<Navigate to={'/crm/customers/list'} />} />
                                <Route path='customers' element={<Navigate to={'/crm/customers/list'} />} />
                                <Route path='customers/list' element={<Customers />} />
                                {/*<Route path='home' element={<Customers/>}/>*/}
                                <Route path='customers/view/:id' element={<Customer />} />
                                <Route path='assets' element={<Navigate to={'/crm/assets/list'} />} />
                                <Route path='assets/list' element={<Assets />} />
                                <Route path='assets/view/:id' element={<ExtendedAsset />} />
                                <Route path='contracts' element={<Navigate to={'/crm/contracts/list'} />} />
                                <Route
                                    path='contracts/list'
                                    element={<Contracts api_call='/api/contracts/getall' />}
                                />
                                <Route path='contracts/view/:id' element={<ExtendedContract />} />
                                <Route path='customers/new' element={<NewCustomer />} />
                                <Route path='assets/new' element={<NewAsset />} />
                                <Route path='contracts/new' element={<NewContract />} />
                                <Route path='customers/edit/:id' element={<EditCustomer />} />
                                <Route path='assets/edit/:id' element={<EditAsset />} />
                                <Route path='contracts/edit/:id' element={<EditContract />} />

                                <Route path='creators' element={<Navigate to={'/crm/creators/list'} />} />
                                <Route
                                    path='creators/list'
                                    element={
                                        <Suspense fallback={<PageLoading />}>
                                            {/*<Creators />*/}
                                            <CreatorsWrapper />
                                        </Suspense>
                                    }
                                >
                                    <Route
                                        path='new'
                                        element={
                                            <Suspense fallback={<PageLoading />}>
                                                <CreatorCreate />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path=':id'
                                        element={
                                            <Suspense fallback={undefined}>
                                                <CreatorViewEdit editable={false} />{' '}
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path=':id/edit'
                                        element={
                                            <Suspense fallback={undefined}>
                                                <CreatorViewEdit editable={true} />{' '}
                                            </Suspense>
                                        }
                                    />
                                </Route>

                                {/*<Route path='leads' element={<CreatorsLeads/>}/>*/}
                                <Route path='leads' element={<Navigate to={'/crm/leads/list'} />} />
                                <Route path='leads/list' element={<CreatorsLeads />}>
                                    <Route path='new' element={<CreatorLeadCreate />} />
                                    <Route path=':id' element={<CreatorLeadViewEdit editable={false} />} />
                                    <Route
                                        path=':id/edit'
                                        element={<CreatorLeadViewEdit editable={true} />}
                                    />
                                </Route>
                                <Route path='leads/search' element={<CreatorsLeadsSearch />} />
                                <Route
                                    path='leads/search/instagram/:handle'
                                    element={<CreatorsLeadsSearchReportInstagram />}
                                />
                                <Route
                                    path='leads/search/youtube/:channel_id'
                                    element={<CreatorsLeadsSearchReportYoutube />}
                                />
                                <Route
                                    path='leads/search/tiktok/:handle'
                                    element={<CreatorsLeadsSearchReportTiktok />}
                                />

                                <Route element={<AccessTesting />}>
                                    {/* <Route path='revenuebrackets' element={<RevenueBrackets />} />
                                <Route
                                    path='revenuebrackets/delete/:id'
                                    element={<DeleteRevenueBracketEntry />}
                                /> */}
                                    <Route path='testing' element={<DDEXMainPage />} />
                                    <Route path='testing/artists' element={<ArtTracksArtistsModal />} />
                                    <Route path='testing/:upc_ean/edit' element={<ReleaseUpdateStepForm />} />
                                </Route>
                            </Route>

                            <Route element={<AccessSocial />}>
                                <Route path='social'>
                                    <Route index element={<Navigate to={'/social/connections'} />} />
                                    <Route path='connections' element={<ConnectionsPage />}>
                                        <Route path='new' element={<NewConnectionModal />} />
                                        <Route path='call' element={<CallModal />} />
                                        <Route path='view/:id' element={<ViewConnectionModal />} />
                                        <Route
                                            path='edit/:id'
                                            element={
                                                <Suspense
                                                    fallback={
                                                        <Modal open>
                                                            <Spin />
                                                        </Modal>
                                                    }
                                                >
                                                    <EditConnectionModal />
                                                </Suspense>
                                            }
                                        />
                                        <Route path='delete/:id' element={<DeleteConnectionModal />} />
                                    </Route>
                                </Route>
                            </Route>

                            <Route path='erp' element={<AccessERP />}>
                                <Route index element={<PaymentsHistory />} />
                                <Route path={'home'} element={<PaymentsHistory />} />
                                <Route path={'newbillablecycle'} element={<NewBillableCycle />} />
                                <Route path={'affiliates'} element={<Affiliates />} />
                                <Route path={'getppks'} element={<PlatformProductKeys />} />
                                <Route path={'unmatched'} element={<Unmatched />} />
                                <Route path={'billablecyclestatus'} element={<BillableCycleStatus />} />
                                <Route path={'rates'} element={<Rates />} />
                                <Route path={'filemanager'} element={<FileManager />} />
                                <Route path={'paymentcycles'} element={<PaymentCycles />} />
                                {/*<Route path={'paymentcycles/:id'} element={<PaymentCyclePanel/>}/>*/}
                                <Route path={'paymentcycles/:id'} element={<PaymentCyclePanel />}>
                                    <Route index element={<RedirectCycleIndex />} />
                                    <Route path='raw_files' element={<PaymentCycleFiles />} />
                                    <Route path='unmatched_blanks' element={<PaymentCycleUnmatchedFiles />} />
                                    <Route path='unmatched_assets' element={<PaymentCycleUnmatchedKeys />} />
                                    <Route path='customers' element={<PaymentCycleCustomers />} />
                                    <Route path='assets' element={<PaymentCycleAssets />} />
                                    <Route path='balances' element={<PaymentCycleBalances />} />
                                    <Route path='rates' element={<PaymentCycleRates />} />
                                    <Route path='analytics' element={<PaymentCycleResultsAnalytics />} />
                                    <Route
                                        path='customer_reports'
                                        element={<PaymentCycleCustomerReports />}
                                    />
                                    <Route
                                        path='video_cms_reports'
                                        element={<PaymentCycleVideoReportsFileVault />}
                                    />
                                    <Route
                                        path='raw_data_file_vault'
                                        element={<PaymentCycleRawDataFileVault />}
                                    />
                                </Route>
                            </Route>

                            <Route path='reporting' element={<AccessReporting />}>
                                <Route index element={<Navigate to={'/reporting/smartreports'} />} />
                                <Route path={'whatif'} element={<WhatIfReportingV2 />} />
                                <Route path={'dashboard'} element={<DashboardReportingV2 />} />
                                <Route path={'queryengine'} element={<AnalyticsQueryEngine />} />
                                <Route path={'smartreports'} element={<SmartReports />} />
                                <Route path={'brandaccounts'} element={<BrandAccountReporting />} />
                                <Route path={'engine'} element={<ReportingChartsEngine />} />
                                <Route path={'videocomparison'} element={<VideoComparison />} />
                                <Route path={'videostats'} element={<VideoStatsReporting />} />
                                <Route path={'benchmarks'}>
                                    <Route
                                        index
                                        element={<Navigate to={'/reporting/benchmarks/youtube'} />}
                                    />
                                    <Route path={'youtube'} element={<YouTubeBenchmarks />} />
                                    <Route path={'tiktok'} element={<TikTokBenchmarks />} />
                                </Route>
                                <Route path={'tiermetrics'} element={<TierMetrics />} />
                            </Route>

                            <Route path='music' element={<AccessMusic />}>
                                <Route index element={<DDEXMainPage />} />
                                <Route path='main' element={<DDEXMainPage />} />
                                <Route path='artists' element={<ArtistsTable />} />
                                <Route path='artists/:key_name/edit' element={<EditArtistForm />} />
                                <Route
                                    path=':upc_ean/edit'
                                    element={
                                        <PageContextProvider>
                                            <ReleaseUpdateStepForm />
                                        </PageContextProvider>
                                    }
                                />
                                <Route
                                    path=':upc_ean/view'
                                    element={
                                        <Suspense fallback={<ModalLoading />}>
                                            <ViewReleaseModal />
                                        </Suspense>
                                    }
                                />
                                <Route path='labels' element={<LabelsTable />} />
                                <Route path='asset_labels' element={<AssetLabelsTable />} />
                                <Route path='labels/:id/edit' element={<AccessMusicAssetLabels />}>
                                    <Route index element={<EditAssetLabelForm />} />
                                </Route>
                            </Route>

                            <Route path='admin' element={<AccessAdmin />}>
                                <Route index element={<AdminDashboard />} />
                            </Route>

                            <Route path='tools'>
                                <Route path={'metadata_converter'} element={<MetadataConverter />} />
                            </Route>
                        </Route>
                    </Route>
                    <Route path='/login' element={<UserLogin />} />
                    <Route path='*' element={<NoFoundPage />} />
                </Routes>
                {background && (
                    <Routes>
                        <Route element={<PrivateRoutes />}>
                            <Route
                                path='crm/creators/:id'
                                element={
                                    <Suspense fallback={undefined}>
                                        <CreatorViewEdit editable={false} />
                                    </Suspense>
                                }
                            />
                            <Route
                                path='crm/creators/:id/edit'
                                element={
                                    <Suspense fallback={undefined}>
                                        <CreatorViewEdit editable={true} />
                                    </Suspense>
                                }
                            />
                            <Route
                                path='crm/creators/list/new'
                                element={
                                    <Suspense fallback={<PageLoading />}>
                                        <CreatorCreate />
                                    </Suspense>
                                }
                            />
                            <Route path='labels' element={<LabelsTable />} />
                            <Route path='labels/:id/edit' element={<EditLabelForm />} />
                            <Route path='labels/:id/delete' element={<DeleteLabelForm />} />
                            <Route path='asset_labels' element={<AssetLabelsTable />} />
                            <Route path='asset_labels/:id' element={<AccessMusicAssetLabels />}>
                                <Route path='edit' element={<EditAssetLabelForm />} />
                                <Route path='delete' element={<DeleteAssetLabelForm />} />
                            </Route>
                        </Route>

                        <Route path='admin' element={<AccessAdmin />}>
                            <Route index element={<AdminDashboard />} />
                        </Route>
                        <Route path='crm/leads/:id' element={<CreatorLeadViewEdit editable={false} />} />
                        <Route path='crm/leads/:id/edit' element={<CreatorLeadViewEdit editable={true} />} />
                        <Route path='crm/leads/list/new' element={<CreatorLeadCreate />} />
                        {/*<Route*/}
                        {/*    path='crm/leads/search'*/}
                        {/*    element={<CreatorsLeadsSearch/>}*/}
                        {/*/>*/}
                        <Route
                            path='crm/leads/search/instagram/:handle'
                            element={<CreatorsLeadsSearchReportInstagram />}
                        />
                        <Route
                            path='crm/leads/search/youtube/:channel_id'
                            element={<CreatorsLeadsSearchReportYoutube />}
                        />
                        <Route
                            path='crm/leads/search/tiktok/:handle'
                            element={<CreatorsLeadsSearchReportTiktok />}
                        />

                        <Route path='social/connections/new' element={<NewConnectionModal />} />
                        <Route path='social/connections/call' element={<CallModal />} />
                        <Route path='social/connections/view/:id' element={<ViewConnectionModal />} />
                        <Route path='social/connections/edit/:id' element={<EditConnectionModal />} />
                        <Route path='social/connections/delete/:id' element={<DeleteConnectionModal />} />
                        <Route path='crm/testing/artists' element={<ArtTracksArtistsModal />} />
                        <Route path='crm/testing/:upc_ean/edit' element={<ReleaseUpdateStepForm />} />
                        <Route path='music/form/artists' element={<ArtTracksArtistsModal />} />
                        <Route
                            path='music/:upc_ean/view'
                            element={
                                <Suspense fallback={<ModalLoading />}>
                                    <ViewReleaseModal />
                                </Suspense>
                            }
                        />
                        <Route
                            path='music/:upc_ean/edit'
                            element={
                                <PageContextProvider>
                                    <ReleaseUpdateStepForm />
                                </PageContextProvider>
                            }
                        />
                        <Route path='music/artists/:key_name/edit' element={<EditArtistForm />} />
                        <Route path='music/artists/:key_name/delete' element={<DeleteArtistForm />} />
                        <Route path='music/labels/:id/edit' element={<EditLabelForm />} />
                        <Route path='music/labels/:id/delete' element={<DeleteLabelForm />} />
                        <Route path='music/asset_labels/:id/edit' element={<EditAssetLabelForm />} />
                        <Route path='music/asset_labels/:id/delete' element={<DeleteAssetLabelForm />} />

                        {/* <Route
                            path='crm/revenuebrackets/delete/:id'
                            element={<DeleteRevenueBracketEntry />}
                        /> */}
                    </Routes>
                )}
            </div>
            {/*<ReactQueryDevtools/>*/}
        </QueryClientProvider>
    );
}

export default App;
