import type {ActionType} from '@ant-design/pro-components';
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {PageContainer, PageHeader} from "@ant-design/pro-layout";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {
    Alert,
    Button,
    ConfigProvider,
    Dropdown,
    Menu,
    Modal,
    notification,
    Progress,
    Row,
    Space,
    Tooltip,
    Typography,
    Upload
} from "antd";
import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    CheckCircleOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    FileTextOutlined,
    MoreOutlined,
    UploadOutlined
} from '@ant-design/icons';
import {postRequest} from "../../../api/postRequest";
import {auth} from "../../../config/firebase";
import axios from "axios";
import {API} from "../../../common/constants";
import {roundNum} from "../../../common/utils";
import {PaymentCycleMeta} from "../PaymentCycles";
import enUSIntl from "antd/lib/locale/en_US";
import {ModalForm} from "@ant-design/pro-form";
import PaymentCycleLogs from "../../components/PaymentCycleLogs";
import PaymentCycleHealthCheck from "../../components/PaymentCycleHealthCheck";
import ProDescriptions from "@ant-design/pro-descriptions";
import moment from "moment/moment";
import axiosApiInstance from "../../../api/axiosClient";


export default () => {
    const [rawFilesPolling, setRawFilesPolling] = useState<false | number>(false)
    const [unmatchedFilesPolling, setUnmatchedFilesPolling] = useState<false | number>(false)
    const [fileList, setFileList] = useState<any[]>([])
    const [downloading, setDownloading] = useState<boolean>(false)
    const [uploading, setUploading] = useState<boolean>(false)
    const [uploadProgress, setUploadProgress] = useState<number | undefined>(undefined)
    const [cycleMeta, setCycleMeta] = useState<PaymentCycleMeta | undefined>(undefined)
    const [loading, setLoading] = useState<boolean>(false)
    const [matching, setMatching] = useState<boolean>(false)
    const [computing, setComputing] = useState<boolean>(false)
    const [unloading, setUnloading] = useState<boolean>(false)
    const [unloadModalOpen, setUnloadModalOpen] = useState<boolean>(false)


    // const [projectData, setProjectData] = useState<API.Project | undefined>(undefined);
    const [tab, setTab] = useState("info");
    // const [tab, setTab] = useState("wbs");
    const actionRef = useRef<ActionType>();

    //maybe use for editing permissions
    // const {can} = UserAuth();
    const location = useLocation();
    const routerParams = useParams();
    const navigate = useNavigate();

    // const getData = async () => {
    //     try {
    //         const res = await axiosApiInstance.get<API.Project>('/api/projects/' + routerParams.projectId)
    //         setProjectData(res.data)
    //     } catch (e) {
    //     }
    // }


    const loadAll = () => {
        setLoading(true)
        notification.info({message: 'Operation started. Please check the logs for status updates.'})
        setRawFilesPolling(2000)
        postRequest('/api/paymentCycles/loadAll/' + routerParams.id).then(value => {
            console.log(value)
            notification.success({message: 'Success'})
            setRawFilesPolling(false)
            setLoading(false)
        }).catch(reason => {
            console.log(reason)
            // notification.error({message: 'Error'})
            setRawFilesPolling(false)
            setLoading(false)
        })
    }

    const unloadAll = () => {
        setUnloading(true)
        notification.info({message: 'Operation started. Please check the logs for status updates.'})
        setRawFilesPolling(2000)
        postRequest('/api/paymentCycles/unloadAll/' + routerParams.id).then(value => {
            console.log(value)
            notification.success({message: `Success (${value.data?.rows_affected} rows affected)`})
            setRawFilesPolling(false)
            setUnloading(false)
        }).catch(reason => {
            console.log(reason)
            // notification.error({message: 'Error'})
            setRawFilesPolling(false)
            setUnloading(false)
        })
    }

    const matchAll = () => {
        setMatching(true)
        notification.info({message: 'Operation started. Please check the logs for status updates.'})
        axiosApiInstance.post('/api/paymentCycles/match/' + routerParams.id).then(value => {
            console.log(value)
            notification.success({message: 'Success'})
            setMatching(false)
        }).catch(reason => {
            console.log(reason)
            setMatching(false)
        })
    }

    const computeAll = () => {
        setComputing(true)
        notification.info({message: 'Operation started. Please check the logs for status updates.'})
        axiosApiInstance.post('/api/paymentCycles/compute/' + routerParams.id).then(value => {
            console.log(value)
            notification.success({message: 'Success'})
            setComputing(false)
        }).catch(reason => {
            console.log(reason)
            setComputing(false)
        })
    }

    const applyUnmatchedFixesAll = () => {
        notification.info({message: 'Operation started. Please check the logs for status updates.'})
        setUnmatchedFilesPolling(2000)
        axiosApiInstance.post('/api/paymentCycles/applyUnmatchedFixesAll/' + routerParams.id).then(value => {
            // console.log(value)
            notification.success({message: 'Success'})
            setUnmatchedFilesPolling(false)
        }).catch(reason => {
            console.log(reason)
            setUnmatchedFilesPolling(false)
        })
    }

    const exportUnmatchedAll = () => {
        notification.info({message: 'Operation started. Please check the logs for status updates.'})
        setUnmatchedFilesPolling(2000)
        postRequest('/api/paymentCycles/exportUnmatchedAll/' + routerParams.id).then(value => {
            // console.log(value)
            notification.success({message: 'Success'})
            setUnmatchedFilesPolling(false)
        }).catch(reason => {
            console.log(reason)
            // notification.error({message: 'Error'})
            setUnmatchedFilesPolling(false)
        })
    }

    const downloadUnmatchedAll = async () => {
        notification.info({message: 'Operation started. Please check the logs for status updates.'})
        const token = await auth.currentUser?.getIdToken()
        axios
            .post(API + '/api/paymentCycles/downloadUnmatchedAll/' + routerParams.id, null,
                {
                    headers: {
                        "Accept": "*/*",
                        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                        Authorization: `Bearer ${token}`
                    },
                    responseType: 'blob'
                })
            .then(res => {
                console.log(res)
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'unmatched.zip');
                document.body.appendChild(link);
                link.click();

                notification.success({message: 'Success'})
            }).catch(reason => {
            console.log(reason)
            notification.error({message: 'Error'})
        })
    }


    const handleUpload = ({fileList}: any) => {

        // console.log('fileList', fileList);

        // you store them in state, so that you can make a http req with them later
        setFileList(fileList)
    };

    const handleSubmit = async (event: any) => {

        setUploading(true)
        event.preventDefault();

        let formData = new FormData();
        // add one or more of your files in FormData
        // again, the original file is located at the `originFileObj` key

        for (const file of fileList)
            formData.append("files", file.originFileObj);
        // formData.append("cycle_id", this.props.match.params.id);
        // console.log(formData.getAll("files"), this.state.fileList)
        const token = await auth.currentUser?.getIdToken()
        // let endpoint = API + '/api/paymentCycles/uploadUnmatched/TESTCYCLE1';
        let endpoint = API + '/api/paymentCycles/uploadUnmatched/' + routerParams.id;
        // console.log(endpoint)
        axios
            // .post(API + '/api/paymentCycles/downloadUnmatchedAll/' + this.props.match.params.id, null,
            .post(endpoint, formData,
                // .post(API + '/api/paymentCycles/uploadUnmatched', formData,
                {
                    headers: {
                        "Accept": "*/*",
                        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                        // 'Content-Type': 'multipart/form-data; boundary=XXX',
                        Authorization: `Bearer ${token}`
                    },
                    // data: formData,
                    onUploadProgress: (progressEvent) => {
                        const {loaded, total} = progressEvent
                        const percent = roundNum((loaded / total) * 100, 2)
                        // console.log('progressEvent', progressEvent, percent);
                        setUploadProgress(roundNum(percent, 2))
                    }
                })
            .then(res => {
                setUploadProgress(100)

                console.log("res", res);

                setTimeout(() => {
                    setFileList([])
                    setUploading(false)
                }, 1000)

                notification.success({message: 'Success'})

            })
            .catch(err => {
                setUploading(false)
                notification.error({message: 'Error'})
                console.log({err});
            });
    };

    const dumpToCentralPayments = () => {
        notification.info({message: 'Operation started. Please check the logs for status updates.'})
        // this.setState({rawFilesPolling: 2000})
        postRequest('/api/paymentCycles/dumpToCentralPayments/' + routerParams.id).then(value => {
            console.log(value)
            notification.success({message: 'Success'})
            // this.setState({rawFilesPolling: false})

        }).catch(reason => {
            console.log(reason)
            // notification.error({message: 'Error'})
            // this.setState({rawFilesPolling: false})

        })
    }

    const makeVisible = () => {
        notification.info({message: 'Operation started. Please check the logs for status updates.'})
        postRequest('/api/paymentCycles/makeVisible/' + routerParams.id).then(value => {
            console.log(value)
            notification.success({message: 'Success'})
        }).catch(reason => {
            console.log(reason)
        })
    }

    const makeHidden = () => {
        notification.info({message: 'Operation started. Please check the logs for status updates.'})
        postRequest('/api/paymentCycles/makeHidden/' + routerParams.id).then(value => {
            console.log(value)
            notification.success({message: 'Success'})
        }).catch(reason => {
            console.log(reason)
        })
    }

    const removeFromCentralPayments = () => {
        notification.info({message: 'Operation started. Please check the logs for status updates.'})
        // this.setState({rawFilesPolling: 2000})
        postRequest('/api/paymentCycles/removeFromCentralPayments/' + routerParams.id).then(value => {
            console.log(value)
            notification.success({message: 'Success'})
            // this.setState({rawFilesPolling: false})

        }).catch(reason => {
            console.log(reason)
            // notification.error({message: 'Error'})
            // this.setState({rawFilesPolling: false})

        })
    }

    useEffect(() => {
        // getData()
    }, [])

    // console.log(location.pathname)
    let tabList

    if (cycleMeta?.is_compound) {
        tabList = [
            {
                tab: 'Analytics',
                key: 'analytics',
            },
            {
                tab: 'Customer Reports',
                key: 'customer_reports',
            },
        ]
    } else {
        tabList = [
            {
                tab: 'Raw Files',
                key: 'raw_files',
            },
            {
                tab: 'Unmatched/Blanks',
                key: 'unmatched_blanks',
            },
            {
                tab: 'Unmatched Assets',
                key: 'unmatched_assets',
            },
            {
                tab: 'Customers',
                key: 'customers',
            },
            {
                tab: 'Assets',
                key: 'assets',
            },
            {
                tab: 'Balances',
                key: 'balances',
            },
            {
                tab: 'Rates',
                key: 'rates',
            },
            {
                tab: 'Analytics',
                key: 'analytics',
            },
            {
                tab: 'Customer Reports',
                key: 'customer_reports',
            },
            {
                tab: 'Video CMS Reports',
                key: 'video_cms_reports',
            },
            {
                tab: 'Raw Data File Vault',
                key: 'raw_data_file_vault',
            },
        ]
    }


    return (
        <PageContainer
            // title={"Case " + (projectData ? projectData?.project_pretty_id : routerParams.projectId)}
            // title={"Project " + routerParams.projectId}
            // breadcrumb={{}}
            tabList={tabList}
            // onTabChange={setTab}
            onTabChange={(path) => {
                navigate(`./${path}`); // <-- sibling path
            }}
            tabActiveKey={location.pathname.split('/')[location.pathname.split('/').length - 1]}
            tabProps={{
                type: "card",
            }}
            content={
                <>
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate(-1)}
                        title={routerParams.id}
                        subTitle="Payment Cycle"
                        style={{padding: 0}}
                        extra={[
                            // VIEW LOGS
                            <ConfigProvider locale={enUSIntl}>
                                <ModalForm
                                    // title="Payment Cycle Logs"
                                    width={1000}
                                    trigger={
                                        <Tooltip title={"Open Logs (Auto updates)"}>
                                            <Button type="default">
                                                <FileTextOutlined/> View Logs
                                            </Button>
                                        </Tooltip>
                                    }
                                    autoFocusFirstInput
                                    modalProps={{
                                        cancelButtonProps: {hidden: true},
                                        cancelText: 'Close',
                                        closable: false
                                    }}
                                    submitter={{
                                        submitButtonProps: {
                                            style: {
                                                display: 'none',
                                            },
                                        },
                                    }}
                                >
                                    <PaymentCycleLogs cycle_id={routerParams.id}/>
                                </ModalForm>
                            </ConfigProvider>,


                            <Dropdown trigger={['click']} key="more" overlay={
                                <Menu>
                                    <Menu.Item>

                                        {/*HEALTH CHECK*/}
                                        <ConfigProvider locale={enUSIntl}>
                                            <ModalForm
                                                // title="Payment Cycle Logs"
                                                width={1000}
                                                trigger={
                                                    <Tooltip
                                                        title={"Run after Matching step and after importing Customers, Assets and Rates"}>
                                                        <Button type="text" size={"small"}>
                                                            <CheckCircleOutlined/> Health Check & Statistics
                                                        </Button>
                                                    </Tooltip>
                                                }
                                                autoFocusFirstInput
                                                modalProps={{
                                                    cancelButtonProps: {hidden: true},
                                                    cancelText: 'Close',
                                                    closable: false
                                                }}
                                                submitter={{
                                                    submitButtonProps: {
                                                        style: {
                                                            display: 'none',
                                                        },
                                                    },
                                                }}
                                            >
                                                <PaymentCycleHealthCheck cycle_id={routerParams.id}/>
                                            </ModalForm>
                                        </ConfigProvider>
                                    </Menu.Item>

                                    <Menu.Item>
                                        {/*DUMP TO CENTRAL PAYMENTS*/}
                                        <ConfigProvider locale={enUSIntl}>
                                            <ModalForm
                                                // title="Payment Cycle Logs"
                                                width={500}
                                                trigger={
                                                    <Tooltip
                                                        title={"Run always before exporting Customer Reports"}>
                                                        <Button type="text" size={"small"}>
                                                            <ArrowRightOutlined/> Dump To Central Payments Table
                                                        </Button>
                                                    </Tooltip>
                                                }
                                                autoFocusFirstInput
                                                modalProps={{
                                                    cancelButtonProps: {hidden: true},
                                                    cancelText: 'No',
                                                    closable: false,
                                                    okText: 'Yes'
                                                }}
                                                onFinish={async formData => {
                                                    dumpToCentralPayments()
                                                    return true
                                                }}
                                                // submitter={{
                                                //     // onSubmit: () => {
                                                //     //     console.log('SUBMIT')
                                                //     // }
                                                //     // submitButtonProps: {
                                                //     //     title: 'Yes',
                                                //     // style: {
                                                //     //     display: 'none',
                                                //     // },
                                                //     // },
                                                // }}
                                            >
                                                {/*<PaymentCycleHealthCheck cycle_id={this.props.match.params.id}/>*/}
                                                <Typography.Text>
                                                    Are you sure you want to insert the records of this cycle into the
                                                    central payments table?
                                                    Once they are there, they can be viewed from the customer/internal
                                                    Portal.
                                                </Typography.Text>
                                            </ModalForm>
                                        </ConfigProvider>

                                    </Menu.Item>

                                    <Menu.Item>
                                        {/*REMOVE FROM TO CENTRAL PAYMENTS*/}
                                        <ConfigProvider locale={enUSIntl}>
                                            <ModalForm
                                                // title="Payment Cycle Logs"
                                                width={500}
                                                trigger={
                                                    <Tooltip
                                                        title={"Run in case of mistake"}>
                                                        <Button type="text" size={"small"}>
                                                            <ArrowLeftOutlined/>Remove From Central Payments Table
                                                        </Button>
                                                    </Tooltip>
                                                }
                                                autoFocusFirstInput
                                                modalProps={{
                                                    cancelButtonProps: {hidden: true},
                                                    cancelText: 'No',
                                                    closable: false,
                                                    okText: 'Yes'
                                                }}
                                                onFinish={async formData => {
                                                    removeFromCentralPayments()
                                                    return true
                                                }}
                                            >
                                                <Typography.Text>
                                                    Are you sure you want to delete the records of this cycle from the
                                                    central payments table?
                                                    Once this is done, they cannot be viewed from the customer/internal
                                                    Portal.
                                                </Typography.Text>
                                            </ModalForm>
                                        </ConfigProvider>
                                    </Menu.Item>

                                    <Menu.Item>
                                        {/*MAKE PUBLICLY VISIBLE IN CENTRAL PAYMENTS*/}
                                        <ConfigProvider locale={enUSIntl}>
                                            <ModalForm
                                                // title="Payment Cycle Logs"
                                                width={500}
                                                trigger={
                                                    <Tooltip
                                                        title={"Run after dumping to Central Payments"}>
                                                        <Button type="text" size={"small"}>
                                                            <EyeOutlined/> Make this Cycle Visible
                                                        </Button>
                                                    </Tooltip>
                                                }
                                                autoFocusFirstInput
                                                modalProps={{
                                                    cancelButtonProps: {hidden: true},
                                                    cancelText: 'No',
                                                    closable: false,
                                                    okText: 'Yes'
                                                }}
                                                onFinish={async formData => {
                                                    makeVisible()
                                                    return true
                                                }}
                                            >
                                                {/*<PaymentCycleHealthCheck cycle_id={this.props.match.params.id}/>*/}
                                                <Typography.Text>
                                                    Are you sure you want to make this cycle visible?
                                                    <br/>
                                                    Once this is done, the data of this cycle will be available in the
                                                    customer/internal
                                                    Portal.
                                                </Typography.Text>
                                            </ModalForm>
                                        </ConfigProvider>

                                    </Menu.Item>

                                    <Menu.Item>
                                        {/*MAKE HIDDEN IN CENTRAL PAYMENTS*/}
                                        <ConfigProvider locale={enUSIntl}>
                                            <ModalForm
                                                // title="Payment Cycle Logs"
                                                width={500}
                                                trigger={
                                                    <Tooltip
                                                        title={"Run after dumping to Central Payments"}>
                                                        <Button type="text" size={"small"}>
                                                            <EyeInvisibleOutlined/> Make this Cycle Hidden
                                                        </Button>
                                                    </Tooltip>
                                                }
                                                autoFocusFirstInput
                                                modalProps={{
                                                    cancelButtonProps: {hidden: true},
                                                    cancelText: 'No',
                                                    closable: false,
                                                    okText: 'Yes'
                                                }}
                                                onFinish={async formData => {
                                                    makeHidden()
                                                    return true
                                                }}
                                            >
                                                {/*<PaymentCycleHealthCheck cycle_id={this.props.match.params.id}/>*/}
                                                <Typography.Text>
                                                    Are you sure you want to make this cycle hidden?
                                                    <br/>
                                                    Once this is done, the data of this cycle will not be available in
                                                    the customer/internal Portal.
                                                </Typography.Text>
                                            </ModalForm>
                                        </ConfigProvider>

                                    </Menu.Item>

                                </Menu>
                            } placement="bottomRight">
                                <Button type="text" icon={<MoreOutlined style={{fontSize: 20}}/>}/>
                            </Dropdown>
                        ]}
                    />
                    <br/>

                    <ConfigProvider locale={enUSIntl}>
                        <ProDescriptions
                            // title="Basic stats"
                            request={async () => {
                                // console.log(params)
                                const res = await postRequest('/api/paymentCycles/getCycleMeta/' + routerParams.id)
                                const data = res.data
                                console.log(res.data)
                                setCycleMeta(res.data)
                                return {data, success: true}
                            }}
                            columns={[
                                {title: 'Cycle Id', dataIndex: 'cycle_id', valueType: 'text',},
                                {
                                    title: 'Creation time',
                                    dataIndex: 'creation_time',
                                    valueType: 'text',
                                    renderText: (text, record, index) => {
                                        return (moment(text, "YYYY-MM-DD kk:mm:ss").format("DD/MM/YYYY kk:mm:ss"))
                                    }
                                },
                                {title: 'Description', dataIndex: 'description', valueType: 'text',},
                                {title: 'Step', dataIndex: 'step', valueType: 'text',},
                                {
                                    title: 'Phase', dataIndex: 'phase', valueType: 'text',
                                    valueEnum: {
                                        done: {
                                            text: 'done',
                                            status: 'success',
                                        },
                                    }
                                },
                                {
                                    title: 'Is Compound',
                                    dataIndex: 'is_compound',
                                    valueEnum: {
                                        false: {
                                            text: 'No',
                                            // status: 'success',
                                        },
                                        true: {
                                            text: 'Yes',
                                            // status: 'success',
                                        },
                                    }
                                },
                                {
                                    title: 'Reference month', dataIndex: 'reference_month', valueType: 'text',
                                    render: (dom, entity, index) =>
                                        (moment(entity.reference_month, "YYYY-MM-DD").format("MMM YYYY"))
                                }
                            ]}
                        />
                    </ConfigProvider>
                </>
            }
        >


            {location.pathname.includes("raw_files") &&
                <Space direction={"vertical"}>
                    <Space>
                        <Button onClick={loadAll} loading={loading}>Load</Button>
                        <Button onClick={matchAll} loading={matching}>Match</Button>
                        <Button onClick={computeAll} loading={computing}>Compute</Button>
                        <Button onClick={() => {
                            setUnloadModalOpen(true)
                        }} loading={unloading}>Unload</Button>
                    </Space>
                    <br/>
                </Space>
            }

            {location.pathname.includes("unmatched_blanks") &&
                <Space direction={"vertical"}>
                    <Row>
                        <Space>
                            <Button onClick={exportUnmatchedAll}>① Export/Overwrite Unmatched
                                Records</Button>
                            <Button onClick={downloadUnmatchedAll}>② Download Exported
                                Records</Button>
                            <Button onClick={applyUnmatchedFixesAll}>④ Apply Fixes</Button>
                        </Space>
                    </Row>

                    <Row>

                        <Alert message={<>
                            <p>Upload the fixed unmatched records by selecting the "Unmatched"
                                directory
                                with
                                same folder
                                structure and file names as when downloaded. Hit "Submit" to upload
                                the
                                files
                                and replace the exported ones.</p>
                            <Row>
                                <Upload
                                    fileList={fileList}
                                    onChange={handleUpload}
                                    beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                                    directory
                                >
                                    {/*<Button icon={<UploadOutlined/>}>Upload Fixed Unmatched Folder</Button>*/}
                                    <Button icon={<UploadOutlined/>}>③ Upload/Overwrite (Fixed)
                                        Unmatched
                                        Records</Button>

                                </Upload>
                                {uploadProgress !== undefined && uploading &&
                                    <Progress percent={uploadProgress} status='active'/>}
                            </Row>
                            {fileList.length > 0 &&
                                <>
                                    <br/>
                                    <Row>
                                        <Button onClick={handleSubmit}
                                                loading={uploading}>
                                            Submit
                                        </Button>
                                    </Row>
                                </>
                            }

                        </>}/>
                    </Row>
                </Space>
            }

            {unloadModalOpen &&
                <Modal
                    title="Unload all"
                    open={unloadModalOpen}
                    onOk={() => {
                        setUnloadModalOpen(false)
                        unloadAll()
                    }}
                    onCancel={() => {
                        setUnloadModalOpen(false)
                    }}
                    okText={'Yes'}
                >
                    <p>Are you sure you want to unload all files?</p>
                </Modal>
            }

            <Outlet/>

        </PageContainer>


    );
};
