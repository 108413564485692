import {useContext, useEffect} from 'react';
import {DDEXPageContext} from './DDEXMainPage';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import ProDescriptions from "@ant-design/pro-descriptions";
import {Divider} from "antd";

dayjs.extend(duration);

const formatDuration = (duration: number) => {
    // Extract whole minutes
    const minutes = Math.floor(duration / 60);

    // Extract remaining whole seconds
    const seconds = Math.floor(duration % 60);

    // Extract milliseconds
    const milliseconds = Math.round((duration % 1) * 1000);

    // Format the result as mm:ss:SSS
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');
    const formattedMilliseconds = String(milliseconds).padStart(3, '0');

    return `${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds}`;
};

const parseDuration = (durationString: string) => {
    // console.log({durationString})
    if (!isNaN(+durationString)) {
        return dayjs.duration(+durationString);
    }
    if (!durationString) {
        return dayjs.duration(0);
    }
    // Split the duration string into minutes, seconds, and milliseconds
    const [minutes, secondsWithMillis] = durationString.split(':');
    const [seconds, milliseconds] = secondsWithMillis.split('.');

    // Create a Day.js duration object
    const dur = dayjs.duration({
        minutes: parseInt(minutes, 10),
        seconds: parseInt(seconds, 10),
        milliseconds: parseInt(milliseconds, 10)
    });

    return dur;
};

const StepsFormThirdPage = () => {
    const {firstFormPageRef, secondFormPageRef, listRef, artists} = useContext(DDEXPageContext);
    // const fileInputRef = useRef(null);

    useEffect(() => {
        console.log('firstFormPageRef', firstFormPageRef.current?.getFieldsValue());
    });
    return (
        <>
            <h2 style={{color: '#1890ff'}}>Release Details</h2>
            <Divider/>
            <ProDescriptions column={2}>
                <ProDescriptions.Item label='Release Title'>
                    {firstFormPageRef.current?.getFieldValue('name') || <i>N/A</i>}
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Version/Subtitle'>
                    {firstFormPageRef.current?.getFieldValue('subtitle') || <i>N/A</i>}
                </ProDescriptions.Item>
                <ProDescriptions.Item label='UPC/EAN'>
                    {firstFormPageRef.current?.getFieldValue('upc_ean') || <i>N/A</i>}
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Main Artists'>
                    {firstFormPageRef.current?.getFieldValue('artists') || <i>N/A</i>}
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Various Artists/Compilation'>
                    {firstFormPageRef.current?.getFieldValue('is_compilation') || <i>N/A</i>}
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Featuring Artists'>
                    {firstFormPageRef.current?.getFieldValue('featuring_artists') || <i>N/A</i>}
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Artist Display Name'>
                    {firstFormPageRef.current?.getFieldValue('artist_display') || <i>N/A</i>}
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Label'>
                    {firstFormPageRef.current?.getFieldValue('label_id') || <i>N/A</i>}
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Asset Label'>
                    {firstFormPageRef.current?.getFieldValue('asset_label_id') || <i>N/A</i>}
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Genre'>
                    {firstFormPageRef.current?.getFieldValue('genre') || <i>N/A</i>}
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Subgenre'>
                    {firstFormPageRef.current?.getFieldValue('sub_genre') || <i>N/A</i>}
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Format'>
                    {firstFormPageRef.current?.getFieldValue('type') || <i>N/A</i>}
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Digital Release Date'>
                    {firstFormPageRef.current?.getFieldValue('digital_release_date') || <i>N/A</i>}
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Original Release Date'>
                    {firstFormPageRef.current?.getFieldValue('original_release_date') || <i>N/A</i>}
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Production Year'>
                    {firstFormPageRef.current?.getFieldValue('production_year') || <i>N/A</i>}
                </ProDescriptions.Item>
                <ProDescriptions.Item label='No. of Tracks'>
                    {firstFormPageRef.current?.getFieldValue('number_of_tracks') || <i>N/A</i>}
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Price Tier'>
                    {firstFormPageRef.current?.getFieldValue('price_tier') || <i>N/A</i>}
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Release Territories'>
                    {firstFormPageRef.current?.getFieldValue('territories') || <i>N/A</i>}
                </ProDescriptions.Item>
                <ProDescriptions.Item label='Cover Art'>
                    {firstFormPageRef.current?.getFieldValue('album_cover_filename') || <i>N/A</i>}
                </ProDescriptions.Item>
            </ProDescriptions>

            <br/>
            <h2 style={{color: '#1890ff'}}>Art track Details</h2>
            <Divider/>

            <ProDescriptions column={6}>
                {secondFormPageRef.current?.getFieldValue('art_tracks')?.map((track, index) => (
                    <>
                        <ProDescriptions.Item label={`Vol. No`}>{track.vol_no || <i>N/A</i>}</ProDescriptions.Item>
                        <ProDescriptions.Item label={`Track No`}>{index + 1}</ProDescriptions.Item>
                        <ProDescriptions.Item label='Title'>{track.title || <i>N/A</i>}</ProDescriptions.Item>
                        <ProDescriptions.Item label='ISRC'>{track.isrc || <i>N/A</i>}</ProDescriptions.Item>
                        {/*<ProDescriptions.Item label='Duration'>{track.duration || <i>N/A</i>}</ProDescriptions.Item>*/}
                        <ProDescriptions.Item label='Artist'>{track.artist || <i>N/A</i>}</ProDescriptions.Item>
                        <ProDescriptions.Item label='Filename'>{track.filename || <i>N/A</i>}</ProDescriptions.Item>
                    </>
                ))}


            </ProDescriptions>
        </>
    );
};

export default StepsFormThirdPage;
