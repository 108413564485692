import {
    ProFormCheckbox,
    ProFormDatePicker,
    ProFormDependency,
    ProFormDigit,
    ProFormRadio,
    ProFormSelect,
    ProFormText,
    ProFormTreeSelect,
    ProFormUploadDragger
} from '@ant-design/pro-form';
import { useCallback, useContext, useEffect, useState } from 'react';
import { DDEXPageContext } from './DDEXMainPage';
import ProCard from '@ant-design/pro-card';
import { checksEnabled, fetchAssetLabelOptions, fetchLabelOptions } from './ReleaseEntryStepForm';
import axiosApiInstance from '../../../api/axiosClient';
import NewArtistForm from './NewArtistForm';
import NewLabelForm from './NewLabelForm';
import {
    countryTreeOptions,
    defaultSelectionsAtom,
    genreOptions,
    genreToSubgenreOptions,
    newReleaseUploadQueueAtom,
    releasePriceTierOptions
} from './ddex_atoms';
import ProFormDatePickerYear from '@ant-design/pro-form/es/components/DatePicker/YearPicker';
import type { UploadFile } from 'antd';
import { Image, message, TreeSelect } from 'antd';
import { Store } from 'antd/es/form/interface';
import axios from 'axios';
import { PlusOutlined } from '@ant-design/icons';
import { useAtom } from 'jotai';

const fetchArtistOptions = async () =>
    axiosApiInstance
        .get<API.MusicDistribution.Artist[]>(`/api/products/artists/`)
        .then(({ data }) => data)
        .then((artists) =>
            artists.map((artist) => ({
                label: artist.key_name,
                value: artist.key_name
            }))
        )
        .catch((reason) => {
            console.error(reason);
            return [];
        });

const createArtistDisplayString = (main_artists: string[], featuring_artists: string[]): string => {
    var s = '';
    if (!main_artists || main_artists.length === 0) {
        return s;
    }

    var delim = ', ';
    if (main_artists.length === 2) delim = ' & ';

    s = s + main_artists.join(delim);

    // if (featuring_artists && featuring_artists.length > 0) {
    //     s = s + ' (ft. ' + featuring_artists.join(', ') + ')';
    // }

    return s;
};

const getBase64 = (file: any): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

const StepsFormFirstPage = ({ initialValues, edit }: StepsFormFirstPageProps) => {
    const { stepFormRef, firstFormPageRef, secondFormPageRef, listRef, setArtists } =
        useContext(DDEXPageContext);

    const [newReleaseUploadQueue, setNewReleaseUploadQueue] = useAtom(newReleaseUploadQueueAtom);
    const [, setDefaultSelections] = useAtom(defaultSelectionsAtom);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    // const [fileList, setFileList] = useState<UploadFile[]>([])

    const { SHOW_PARENT } = TreeSelect;

    // useEffect(() => console.log('first form page rendered'));

    useEffect(() => {
        // console.log(
        //     Array.prototype.concat(
        //         ...countryTreeOptions[0].children.map((o) =>
        //             o.children ? o.children.map((c) => c.value) : [o.value]
        //         )
        //     )
        // );
        // console.log(countryTreeOptions);
    });

    const handleFirstPageFlip = async (
        main_artists: API.MusicDistribution.Artist[],
        featuring_artists: API.MusicDistribution.Artist[]
    ) => {
        const tracks = secondFormPageRef.current?.getFieldValue('art_tracks');
        secondFormPageRef.current?.setFieldValue(
            'art_tracks',
            tracks.map((track: API.DDEX.Track) => ({ ...track, main_artists, featuring_artists }))
        );
    };

    const handleTrackNumbers = useCallback(
        (n: number, noOfTracks: number) => {
            if (n > noOfTracks) {
                listRef.current.add(
                    { isrc: '', track_artist_id: firstFormPageRef.current?.getFieldValue('main_artist_id') },
                    n
                );
            } else {
                listRef.current.remove(n);
            }
        },
        [listRef]
    );

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
    };

    // const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    //     setFileList(newFileList);

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type='button'>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );

    return (
        <ProCard colSpan={24} direction='row' style={{ width: 1600 }} split='vertical'>
            <ProCard colSpan={12}>
                <ProFormText
                    name={'name'}
                    label='Release Title'
                    placeholder={'Main Title of Release'}
                    required
                    rules={[
                        {
                            required: checksEnabled
                        }
                    ]}
                />
                <ProFormText
                    name={'subtitle'}
                    label='Version/Subtitle'
                    placeholder={'Release Subtitle (optional)'}
                />
                <ProFormText
                    name={'upc_ean'}
                    label='UPC/EAN'
                    placeholder={`The Release's UPC/EAN Code`}
                    // hasFeedback
                    required
                    rules={[
                        {
                            validator(rule, value, callback) {
                                const upc = value as string;
                                if (typeof upc !== 'string' || upc.length !== 13 || !/^\d{13}$/.test(upc)) {
                                    return callback('UPC needs to have 13 numerical digits');
                                }

                                // Split the string into an array of digits
                                const digits = upc.split('').map(Number);

                                // Calculate the check digit (last digit)
                                const checkDigit = digits.pop(); // Remove the 13th digit to validate against

                                // Calculate the weighted sum of the first 12 digits
                                const sum = digits.reduce((acc, digit, index) => {
                                    return acc + (index % 2 === 0 ? digit * 1 : digit * 3); // Multiply odds by 1 and evens by 3
                                }, 0);

                                // Calculate the expected check digit
                                const calculatedCheckDigit = (10 - (sum % 10)) % 10;

                                // Compare the calculated check digit with the provided one
                                // return calculatedCheckDigit === checkDigit;
                                if (calculatedCheckDigit !== checkDigit) {
                                    return callback(
                                        'Invalid UPC. The code you entered does not meet the requirements'
                                    );
                                }
                                // return callback();
                                callback();
                            },
                            required: checksEnabled,
                            validateTrigger: 'onFinish'
                        }
                    ]}
                />
                <ProFormSelect
                    name={'artists'}
                    label='Main Artists'
                    mode='multiple'
                    request={fetchArtistOptions}
                    fieldProps={{
                        onChange(value) {
                            const main_artists = value;
                            const featuring_artists =
                                firstFormPageRef.current?.getFieldValue('featuring_artists');

                            const artist_display = createArtistDisplayString(main_artists, featuring_artists);
                            firstFormPageRef.current?.setFieldValue('artist_display', artist_display);

                            setDefaultSelections((prev) => ({ ...prev, main_artists: value }));
                        },
                        dropdownRender(menu) {
                            return (
                                <>
                                    {menu}
                                    <NewArtistForm stepFormRef={stepFormRef} />
                                </>
                            );
                        }
                    }}
                    required
                    rules={[
                        {
                            required: checksEnabled
                        }
                    ]}
                />
                <ProFormCheckbox
                    name={'is_compilation'}
                    label={'Various Artists/Compilation'}
                    placeholder={'Various Artists/Compilation'}
                />
                <ProFormSelect
                    name={'featuring_artists'}
                    label='Featuring Artists'
                    mode='multiple'
                    request={fetchArtistOptions}
                    fieldProps={{
                        onChange(value) {
                            setArtists((prev) => ({ ...prev, featuring_artists: value }));
                            setDefaultSelections((prev) => ({ ...prev, featuring_artists: value }));
                        },
                        dropdownRender(menu) {
                            return (
                                <>
                                    {menu}
                                    <NewArtistForm stepFormRef={stepFormRef} />
                                </>
                            );
                        }
                    }}
                />
                <ProFormText name={'artist_display'} label='Artist Display Name' />
                <ProFormSelect
                    name={'label_id'}
                    label='Label'
                    placeholder={`The Release's Record Label`}
                    // options={labelOptions}
                    request={fetchLabelOptions}
                    fieldProps={{
                        showSearch: true,
                        dropdownRender(menu) {
                            return (
                                <>
                                    {menu}
                                    <NewLabelForm stepFormRef={stepFormRef} />
                                </>
                            );
                        }
                    }}
                    required
                    rules={[
                        {
                            required: checksEnabled
                        }
                    ]}
                />
                {/* <ProFormSelect
                    name={['label', 'asset_label_id']}
                    label='Asset Label'
                    placeholder={`YouTube Asset Label`}
                    // options={labelOptions}
                    request={fetchAssetLabelOptions}
                    fieldProps={{
                        showSearch: true
                    }}
                    required
                    rules={[
                        {
                            required: checksEnabled
                        }
                    ]}
                /> */}
                <ProFormSelect
                    name={'asset_label_id'}
                    label='Asset Label'
                    placeholder={`YouTube Asset Label`}
                    // options={labelOptions}
                    request={fetchAssetLabelOptions}
                    fieldProps={{
                        showSearch: true
                    }}
                    // required
                    // rules={[
                    //     {
                    //         required: checksEnabled
                    //     }
                    // ]}
                />

                <ProFormSelect
                    name='genre'
                    label='Genre'
                    options={genreOptions}
                    showSearch
                    fieldProps={{
                        onChange(value, option) {
                            firstFormPageRef.current?.setFieldValue('sub_genre', undefined);
                        },
                        onClear() {
                            firstFormPageRef.current?.setFieldValue('sub_genre', undefined);
                        }
                    }}
                    required
                    rules={[
                        {
                            required: checksEnabled
                        }
                    ]}
                />
                <ProFormDependency name={['genre']}>
                    {({ genre }) => (
                        <ProFormSelect
                            name='sub_genre'
                            label='Subgenre'
                            options={genreToSubgenreOptions[genre]}
                            showSearch
                        />
                    )}
                </ProFormDependency>
            </ProCard>
            <ProCard>
                <ProFormRadio.Group
                    name='type'
                    label='Format'
                    radioType='button'
                    initialValue={'ALBUM'}
                    options={['ALBUM', 'SINGLE', 'EP']}
                    fieldProps={{
                        onChange(e) {
                            // When release type changes, set the price tier to the lowest available option
                            const price_options = releasePriceTierOptions[e.target.value];
                            firstFormPageRef.current?.setFieldValue('price_tier', price_options[0].value);
                        }
                    }}
                />
                <ProFormDatePicker
                    name='digital_release_date'
                    label='Digital Release Date'
                    placeholder={'Release Date'}
                    // dataFormat='YYYY-MM-DDTHH:mm:ssZ'
                    normalize={(value) => value?.format('YYYY-MM-DDTHH:mm:ssZ')}
                    fieldProps={{
                        // format: 'YYYY-MM-DDTHH:mm:ssZ'
                        onChange(value, dateString) {
                            firstFormPageRef.current?.setFieldsValue({
                                original_release_date: value,
                                production_year: value
                            });
                        }
                    }}
                    required
                    rules={[{ required: checksEnabled }]}
                />
                <ProFormDatePicker
                    name='original_release_date'
                    label='Original Release Date'
                    placeholder={'Physical/Original Release Date'}
                    // dataFormat='YYYY-MM-DDTHH:mm:ssZ'
                    normalize={(value) => value?.format('YYYY-MM-DDTHH:mm:ssZ')}
                    fieldProps={
                        {
                            // format: 'YYYY-MM-DDTHH:mm:ssZ'
                        }
                    }
                    required
                    rules={[{ required: checksEnabled }]}
                />
                <ProFormDatePickerYear
                    label='Production Year'
                    name='production_year'
                    normalize={(value) => value?.format('YYYY-MM-DDTHH:mm:ssZ')}
                    required
                    rules={[{ required: checksEnabled }]}
                />
                {/* </ProFormGroup> */}
                {/* <ProFormDigit
                    name='number_of_tracks'
                    width='xs'
                    label='No. of Tracks'
                    min={1}
                    initialValue={1}
                    fieldProps={{
                        onChange(value) {
                            handleTrackNumbers(value as number, listRef.current?.getList().length);
                        }
                    }}
                /> */}
                <ProFormDependency name={['type']}>
                    {({ type }) => {
                        const options = releasePriceTierOptions[type];
                        return (
                            <ProFormSelect
                                label='Price Tier'
                                name='price_tier'
                                initialValue={options ? options[0].value : undefined}
                                options={options}
                            />
                        );
                    }}
                </ProFormDependency>
                <ProFormTreeSelect
                    label='Release Territories'
                    name='territories'
                    initialValue={'Worldwide'}
                    // options={countryTreeOptions}
                    request={async () => countryTreeOptions}
                    fieldProps={{
                        showCheckedStrategy: SHOW_PARENT,
                        showSearch: true,
                        treeCheckable: true,
                        maxTagCount: 5
                    }}
                />
                <ProFormText hidden name='album_cover_filename' />

                <ProFormUploadDragger
                    title='Cover Art'
                    name={'cover_art_file'}
                    description={`Drag and drop image file for the release's cover art`}
                    isList
                    // max={1}
                    // listType="picture-card"

                    // fileList={fileList}
                    // onPreview={handlePreview}
                    // onChange={handleChange}
                    fieldProps={{
                        listType: 'picture',
                        // listType: 'picture-card',
                        maxCount: 1,
                        // iconRender(file, listType) {
                        //     return file;
                        // },
                        // fileList,
                        onPreview: handlePreview,
                        beforeUpload: (file) => {
                            console.log(file);
                            firstFormPageRef?.current?.setFieldValue('album_cover_filename', file.name);
                            return true;
                        },
                        customRequest: async ({ file, onSuccess, onError, onProgress }) => {
                            // console.log(file);
                            if (!edit) {
                                onSuccess('ok');
                                return;
                            }
                            // return Promise.resolve(true)
                            try {
                                const response = await axiosApiInstance.post(
                                    '/api/products/releases/generate-presigned-url-upload',
                                    {
                                        fileName: (file as any).name,
                                        fileType: (file as any).type,
                                        folderName: firstFormPageRef?.current?.getFieldValue('upc_ean')
                                    }
                                );
                                // console.log({response});

                                const { presignedUrl } = response.data;

                                return axios
                                    .put(presignedUrl, file, {
                                        headers: {
                                            'Content-Type': (file as any).type
                                        },
                                        onUploadProgress: (progressEvent) => {
                                            const percentCompleted = Math.round(
                                                (progressEvent.loaded * 100) / progressEvent.total
                                            );
                                            onProgress({ percent: percentCompleted });
                                        }
                                    })
                                    .then(() => {
                                        message.success('File uploaded successfully');
                                        // onSuccess(responseUpl.data)
                                        onSuccess('ok');
                                    })
                                    .catch((err) => {
                                        console.error('Error uploading file:', err);
                                        message.error('Error uploading file');
                                        onError(err);
                                    });
                            } catch (err) {
                                console.error('Error uploading file:', err);
                                message.error('Error uploading file');
                                onError(err);
                            }
                        }
                    }}
                />

                {previewImage && (
                    <Image
                        wrapperStyle={{ display: 'none' }}
                        preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) => setPreviewOpen(visible)
                            // afterOpenChange: (visible) => !visible && setPreviewImage(''),
                        }}
                        src={previewImage}
                    />
                )}
            </ProCard>
        </ProCard>
    );
};

type StepsFormFirstPageProps = {
    initialValues?: Store;
    edit: boolean;
};

export default StepsFormFirstPage;
