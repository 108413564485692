import * as React from 'react';
import { UserAuth } from '../context/AuthContext';
import UnauthorizedPage from '../pages/403';
import { Outlet } from 'react-router-dom';

//Helper wrapper component used for authorization purposes

const AccessMusic = (): JSX.Element => {
    const { can } = UserAuth();

    const accessible = can('read', 'music::data');

    if (!accessible) {
        return <UnauthorizedPage message={'Sorry, you are not authorized to access this content.'} />;
    }

    return <Outlet />;
};

export default AccessMusic;
