import {
    ProFormDigit,
    ProFormGroup,
    ProFormItem,
    ProFormList,
    ProFormRadio,
    ProFormSelect,
    ProFormSwitch,
    ProFormText,
    StepsForm
} from '@ant-design/pro-form';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { countryCodes, countryOptions, countryTreeOptions } from './ddex_atoms';
import ProCard from '@ant-design/pro-card';
import { Badge, Button, Modal, Result, Tooltip } from 'antd';
import { DeleteOutlined, DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { useAtom } from 'jotai';
import axiosApiInstance from '../../../api/axiosClient';
import { DDEXPageContext, isModalOpenAtom, PageContextProvider } from './DDEXMainPage';
import StepsFormFirstPage from './StepFormFirstPage';
import StepsFormSecondPage from './StepsFormSecondPage';
import { Store } from 'antd/es/form/interface';
import { useNavigate, useParams, redirect } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { strict } from 'assert';
import StepsFormThirdPage from './StepsFormThirdPage';

export const checksEnabled = false;
// export const checksEnabled = true;
const REDIRECT_URL = '/music';

const getExcludedTerritories = (q: string) =>
    countryCodes.filter((element) => !q.split(/[,;]+/).includes(element)).join(',');

export const fetchLabelOptions = async () =>
    axiosApiInstance
        .get<API.MusicDistribution.Label[]>(`/api/products/labels/`)
        .then(({ data }) =>
            data.map((label) => ({
                label: label.name,
                value: label.ID
            }))
        )
        .catch((reason) => {
            console.error(reason);
            return [];
        });

export const fetchAssetLabelOptions = async () =>
    axiosApiInstance
        .get<API.MusicDistribution.AssetLabel[]>(`/api/products/asset_labels/`)
        .then(({ data }) =>
            data.map((label) => ({
                label: label.name,
                value: label.ID
            }))
        )
        .catch((reason) => {
            console.error(reason);
            return [];
        });

export const fetchArtistOptions = async () =>
    axiosApiInstance
        .get<API.MusicDistribution.Artist[]>(`/api/products/artists/`)
        .then(({ data }) => data)
        .then((artists) =>
            artists.map((artist) => ({
                label: artist.key_name,
                value: artist.key_name
            }))
        )
        .catch((reason) => {
            console.error(reason);
            return [];
        });

const ReleaseUpdateStepForm = () => {
    const { stepFormRef, firstFormPageRef, secondFormPageRef, releasesActionRef } =
        useContext(DDEXPageContext);
    const navigate = useNavigate();
    const params = useParams();
    const [currentStep, setCurrentStep] = useState(0);

    const { data, status } = useQuery({
        queryKey: ['release', params.upc_ean],
        queryFn: ({ queryKey }) => {
            console.log('query key: ', queryKey);
            return axiosApiInstance
                .get<API.MusicDistribution.MusicRelease>(`/api/products/releases/${queryKey[1]}`, {
                    params: {
                        with_label: '',
                        with_artists: '',
                        with_track_artists: ''
                    }
                })
                .then(({ data }) => {
                    // console.log(data);
                    const cover_art_file = data.album_cover_filename
                        ? [{ name: data.album_cover_filename }]
                        : [];
                    // console.log({ cover_art_file });
                    return {
                        ...data,
                        cover_art_file,
                        artists: data.artists.map(({ key_name }) => key_name),
                        featuring_artists: data.featuring_artists.map(({ key_name }) => key_name),
                        territories: (data.territories as string).split(';'),
                        art_tracks: data.art_tracks.map((track) => ({
                            ...track,
                            audio_file: track.filename ? [{ name: track.filename }] : [],
                            main_artists: track.main_artists.map(({ key_name }) => key_name),
                            featuring_artists: track.featuring_artists.map(({ key_name }) => key_name),
                            composers: track.composers.map(({ key_name }) => key_name),
                            authors: track.authors.map(({ key_name }) => key_name),
                            remixers: track.remixers.map(({ key_name }) => key_name),
                            producers: track.producers.map(({ key_name }) => key_name),
                            arrangers: track.arrangers.map(({ key_name }) => key_name),
                            publishers: track.publishers.map(({ key_name }) => key_name)
                        }))
                    };
                })
                .catch((reason) => {
                    console.error(reason);
                    return [];
                });
        }
    });

    // const handleFirstPageFlip = async (
    //     main_artists: API.MusicDistribution.Artist[],
    //     featuring_artists: API.MusicDistribution.Artist[]
    // ) => {
    //     const tracks = secondFormPageRef.current?.getFieldValue('art_tracks');
    //     secondFormPageRef.current?.setFieldValue(
    //         'art_tracks',
    //         tracks.map((track: API.DDEX.Track) => ({ ...track, main_artists, featuring_artists }))
    //     );
    // };

    const renderMap = {
        error: <Result status='error' />,
        loading: <ProCard loading />
    };

    return (
        <StepsForm
            formRef={stepFormRef}
            stepsFormRender={(dom, submitter) => (
                <Modal
                    width={1600}
                    onCancel={() => navigate(REDIRECT_URL)}
                    onOk={() => {
                        console.log(countryTreeOptions);
                    }}
                    // onCancel={() => redirect(REDIRECT_URL)}
                    footer={submitter}
                    destroyOnClose
                    open
                >
                    {/* <div style={{ marginTop: 36, marginBottom: 20 }}>{dom}</div> */}
                    <div style={{ marginTop: 36, marginBottom: 20 }}>
                        {status === 'success' ? dom : renderMap[status]}
                        {/* {dom} */}
                    </div>
                </Modal>
            )}
            onFinish={async (values) => {
                // console.log('is territories an instance of array? ', values.territories instanceof Array);

                const territories =
                    values.territories instanceof Array
                        ? values.territories.join(';')
                        : values.territories === ''
                        ? 'Worldwide'
                        : values.territories;

                const excluded_territories = getExcludedTerritories(territories);

                values = {
                    ...values,
                    territories,
                    excluded_territories,
                    artists: values.artists.map((key_name) => ({ key_name })),
                    featuring_artists: values.featuring_artists?.map((key_name) => ({ id: key_name })),
                    art_tracks: values.art_tracks.map((track) => ({
                        ...track,
                        main_artists: track.main_artists?.map((key_name) => ({ key_name })),
                        featuring_artists: track.featuring_artists?.map((key_name) => ({ key_name })),
                        composers: track.composers?.map((key_name) => ({ key_name })),
                        authors: track.authors?.map((key_name) => ({ key_name })),
                        remixers: track.remixers?.map((key_name) => ({ key_name })),
                        producers: track.producers?.map((key_name) => ({ key_name })),
                        arrangers: track.arrangers?.map((key_name) => ({ key_name })),
                        publishers: track.publishers?.map((key_name) => ({ key_name }))
                    }))
                };
                console.log({ values });
                await axiosApiInstance
                    .put(`/api/products/releases/`, values, {
                        params: {
                            strict: true
                        }
                    })
                    // .then(() => releasesActionRef?.current?.reload())
                    .catch((reason) => console.log(reason))
                    .finally(() => navigate(REDIRECT_URL));
                return true;
            }}
            onCurrentChange={(current) => {
                // console.log('current: ', current);
                setCurrentStep(current);
            }}
        >
            <StepsForm.StepForm
                title='Release Information'
                layout='horizontal'
                formRef={firstFormPageRef}
                initialValues={data}
                labelAlign='left'
                grid={true}
                rowProps={{
                    gutter: 4
                }}
                // request={() =>
                //     // refetch().then(({}))
                // }
                colProps={{ span: 24 }}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 24 }}
                dateFormatter={(value) => value.format('YYYY-MM-DDTHH:mm:ssZ')}
                // onFinish={async ({ artists, featuring_artists }) => {
                //     await handleFirstPageFlip(artists, featuring_artists);
                //     return true;
                // }}
                onFinish={async () => {
                    return true;
                }}
            >
                <StepsFormFirstPage edit={true} />
            </StepsForm.StepForm>
            <StepsForm.StepForm
                title='Art Tracks'
                initialValues={data}
                formRef={secondFormPageRef}
                stepProps={{ description: 'Input information for each Art Track' }}
                //     initialValues={initialObject}
            >
                <StepsFormSecondPage edit={true} />
            </StepsForm.StepForm>

            <StepsForm.StepForm title='Submit' stepProps={{ description: 'Preview & Submit Release' }}>
                {/*<Result status='success' title='Release has been submitted successfully' />*/}
                {currentStep === 2 && <StepsFormThirdPage />}
            </StepsForm.StepForm>
        </StepsForm>
    );
};

export default ReleaseUpdateStepForm;
