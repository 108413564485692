import { StepsForm } from '@ant-design/pro-form';
import React, { useContext, useState } from 'react';
import { message, Modal } from 'antd';
import { useAtom } from 'jotai';
import axiosApiInstance from '../../../api/axiosClient';
import { DDEXPageContext, isModalOpenAtom } from './DDEXMainPage';
import StepsFormFirstPage from './StepFormFirstPage';
import StepsFormSecondPage from './StepsFormSecondPage';
import StepsFormThirdPage from './StepsFormThirdPage';
import axios from 'axios';

export const checksEnabled = true;
// export const checksEnabled = false;

export const fetchLabelOptions = async () =>
    axiosApiInstance
        .get<API.MusicDistribution.Label[]>(`/api/products/labels/`)
        .then(({ data }) =>
            data.map((label) => ({
                label: label.name,
                value: label.ID
            }))
        )
        .catch((reason) => {
            console.error(reason);
            return [];
        });

export const fetchAssetLabelOptions = async () =>
    axiosApiInstance
        .get<API.MusicDistribution.AssetLabel[]>(`/api/products/asset_labels/`)
        .then(({ data }) =>
            data.map((label) => ({
                label: label.name,
                value: label.ID
            }))
        )
        .catch((reason) => {
            console.error(reason);
            return [];
        });

export const fetchArtistOptions = async () =>
    axiosApiInstance
        .get<API.MusicDistribution.Artist[]>(`/api/products/artists/`)
        .then(({ data }) => data)
        .then((artists) =>
            artists.map((artist) => ({
                label: artist.key_name,
                value: artist.key_name
            }))
        )
        .catch((reason) => {
            console.error(reason);
            return [];
        });

const ReleaseEntryStepForm = () => {
    const { stepFormRef, firstFormPageRef, secondFormPageRef, releasesActionRef } =
        useContext(DDEXPageContext);
    const [isOpen, setIsOpen] = useAtom(isModalOpenAtom);
    const [currentStep, setCurrentStep] = useState(0);
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleFirstPageFlip = async (
        main_artists: API.MusicDistribution.Artist[],
        featuring_artists: API.MusicDistribution.Artist[]
    ) => {
        const tracks = secondFormPageRef.current?.getFieldValue('art_tracks');
        secondFormPageRef.current?.setFieldValue(
            'art_tracks',
            tracks.map((track: API.DDEX.Track) => ({ ...track, main_artists, featuring_artists }))
        );
    };

    const handleFileResourcesUpload = async () => {
        const uploadPromises = [];

        const coverArtFile = firstFormPageRef.current?.getFieldValue('cover_art_file');
        console.log('ca: ', coverArtFile);
        const uploadCoverArt = async (file) => {
            try {
                const response = await axiosApiInstance.post(
                    '/api/products/releases/generate-presigned-url-upload',
                    {
                        fileName: file.name,
                        fileType: file.type,
                        folderName: firstFormPageRef?.current?.getFieldValue('upc_ean')
                    }
                );

                const { presignedUrl } = response.data;

                await axios.put(presignedUrl, file, {
                    headers: {
                        'Content-Type': file.type
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        console.log(`Cover art upload progress: ${percentCompleted}%`);
                        setUploadProgress(uploadProgress + percentCompleted / uploadPromises.length);
                    }
                });

                // message.success('Cover art uploaded successfully');
            } catch (err) {
                console.error('Error uploading cover art:', err);
                message.error('Error uploading cover art');
            }
        };

        const uploadArtTrack = async (file, index) => {
            try {
                const response = await axiosApiInstance.post(
                    '/api/products/releases/generate-presigned-url-upload',
                    {
                        fileName: file.name,
                        fileType: file.type,
                        folderName: firstFormPageRef?.current?.getFieldValue('upc_ean')
                    }
                );

                const { presignedUrl } = response.data;

                await axios.put(presignedUrl, file, {
                    headers: {
                        'Content-Type': file.type
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        console.log(`Art track ${index} upload progress: ${percentCompleted}%`);
                        setUploadProgress(uploadProgress + percentCompleted / uploadPromises.length);
                    }
                });

                secondFormPageRef?.current?.setFieldValue(['art_tracks', index, 'is_uploaded'], true);
                // message.success(`Art track ${index} uploaded successfully`);
            } catch (err) {
                console.error(`Error uploading art track ${index}:`, err);
                message.error(`Error uploading art track ${index}`);
            }
        };

        const artTracks = secondFormPageRef.current?.getFieldValue('art_tracks');

        if (coverArtFile && coverArtFile.length > 0) {
            uploadPromises.push(uploadCoverArt(coverArtFile[0].originFileObj));
        }

        artTracks.forEach((track, index) => {
            const audio = track.audio_file;
            // console.log('audio: ', audio);
            if (audio && audio.length > 0) {
                uploadPromises.push(uploadArtTrack(audio[0].originFileObj, index));
            }
        });

        return Promise.all(uploadPromises);
    };

    return (
        // <ProCard>
        <StepsForm
            formRef={stepFormRef}
            stepsFormRender={(dom, submitter) => (
                <Modal
                    open={isOpen}
                    width={1600}
                    onCancel={() => setIsOpen(false)}
                    footer={submitter}
                    destroyOnClose
                >
                    <div style={{ marginTop: 36, marginBottom: 20 }}>{dom}</div>
                </Modal>
            )}
            onFinish={async (values) => {
                values = {
                    ...values,
                    artists: values.artists?.map((key_name) => ({ key_name })),
                    featuring_artists: values.featuring_artists?.map((key_name) => ({ id: key_name })),
                    art_tracks: values.art_tracks.map((track) => ({
                        ...track,
                        main_artists: track.main_artists?.map((key_name) => ({ key_name })),
                        featuring_artists: track.featuring_artists?.map((key_name) => ({ key_name })),
                        composers: track.composers?.map((key_name) => ({ key_name })),
                        authors: track.authors?.map((key_name) => ({ key_name })),
                        remixers: track.remixers?.map((key_name) => ({ key_name })),
                        producers: track.producers?.map((key_name) => ({ key_name })),
                        arrangers: track.arrangers?.map((key_name) => ({ key_name })),
                        publishers: track.publishers?.map((key_name) => ({ key_name }))
                    }))
                };
                console.log(values);

                if (!firstFormPageRef.current?.getFieldValue('upc_ean')) {
                    message.error('UPC/EAN is required');
                    return false;
                }
                await handleFileResourcesUpload();
                // return false;
                message.success(`Resources uploaded successfully`);

                await axiosApiInstance.post(`/api/products/releases/`, values);
                // message.success('submitted successfully');
                releasesActionRef?.current?.reload();
                // setIsOpen(false);
                // setIsOpen(false);
                setUploadProgress(0);
                return true;
            }}
            onCurrentChange={(current) => {
                // console.log('current: ', current);
                setCurrentStep(current);
            }}
            stepsProps={{ percent: uploadProgress * 100 }}
        >
            <StepsForm.StepForm
                title='Release Information'
                layout='horizontal'
                formRef={firstFormPageRef}
                labelAlign='left'
                grid={true}
                rowProps={{
                    gutter: 4
                }}
                colProps={{ span: 24 }}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 24 }}
                dateFormatter={(value) => value.format('YYYY-MM-DDTHH:mm:ssZ')}
                onFinish={async ({ artists, featuring_artists }) => {
                    await handleFirstPageFlip(artists, featuring_artists);
                    return true;
                }}
            >
                <StepsFormFirstPage
                    initialValues={{
                        upc_ean: 12345600000
                    }}
                    edit={false}
                />
            </StepsForm.StepForm>
            <StepsForm.StepForm
                title='Art Tracks'
                formRef={secondFormPageRef}
                stepProps={{ description: 'Input information for each Art Track' }}
            >
                <StepsFormSecondPage edit={false} />
            </StepsForm.StepForm>

            <StepsForm.StepForm
                title='Submit'
                stepProps={{ description: 'Preview & Submit Release', tailContent: 'ASDSADSA' }}
            >
                {/*<Result status='success' title='Release has been submitted successfully' />*/}
                {currentStep === 2 && <StepsFormThirdPage />}
            </StepsForm.StepForm>
        </StepsForm>
        // </ProCard>
    );
};

export default ReleaseEntryStepForm;
