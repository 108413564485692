import {ConfigProvider, Dropdown, message, Modal, notification, Space} from 'antd';
import React, {useState} from 'react';
import './index.css';
import enUSIntl from "antd/lib/locale/en_US";
import ProTable, {ProColumns} from "@ant-design/pro-table";
import {postRequest} from "../../../api/postRequest";
import {FileTextOutlined} from "@ant-design/icons";
import {humanFileSize} from "../../../common/utils";
import withRouter from "../../../components/withRouter";
import axiosApiInstance from "../../../api/axiosClient";


export interface IPaymentCycleFilesProps {
    //Here we pass the Props Interface
    pageSize?: number
    polling?: false | number
    params: any
}

type PaymentCycleFile = {
    cycle_id: string;
    filename: string;
    filepath: string;
    size: number;
    step: string;
    phase: string;
};


//class ComponentName Component<PropsInterface, StateInterface>
const PaymentCycleFiles: React.FC<IPaymentCycleFilesProps> = (props) => {

    const [describeModalOpen, setDescribeModalOpen] = useState(false)
    const [describeModalFilepath, setDescribeModalFilepath] = useState('')
    const [unloadFileModalOpen, setUnloadFileModalOpen] = useState(false)
    const [unloadFileModalFilename, setUnloadFileModalFilename] = useState('')

    const columns: ProColumns<PaymentCycleFile>[] = [
        {
            title: 'Filename', dataIndex: 'filename', valueType: 'text',
        },
        {
            title: 'Product', dataIndex: 'product', valueType: 'text'
        },
        {
            title: 'Size',
            dataIndex: 'size',
            // render: (num) => (((num as number) / (1024 * 1024))?.toLocaleString('el-GR')),
            render: (num) => humanFileSize(num as number),
            sorter: (a, b) => a.size - b.size, defaultSortOrder: "descend"
        },
        {
            title: 'Step', dataIndex: 'step', valueType: 'text',
        },
        {
            title: 'Phase', dataIndex: 'phase', valueType: 'text',
            valueEnum: row => ({
                // all: { text: 'processing', status: 'processing' },
                default: {
                    text: 'default',
                    status: 'default',
                },
                processing: {
                    text: 'processing',
                    status: 'processing',
                },
                success: {
                    text: 'success',
                    status: 'success',
                },
                error: {
                    text: 'error',
                    status: 'error',
                },
            })
        },
        {
            title: 'Action',
            // dataIndex: 'size',
            // render: (num) => (((num as number) / (1024 * 1024))?.toLocaleString('el-GR')),
            // render: (dom, entity) => <Button onClick={
            //     () => this.loadOne(entity.filename)
            // }>Load</Button>,
            render: (dom, entity) => <Dropdown.Button
                menu={{
                    items: [{
                        key: 'describe',
                        label: 'Describe',
                        onClick: () => {
                            setDescribeModalFilepath(entity.filepath)
                            setDescribeModalOpen(true)
                        }
                    }, {
                        key: 'unload',
                        label: 'Unload',
                        onClick: () => {
                            setUnloadFileModalFilename(entity.filename)
                            setUnloadFileModalOpen(true)
                        }
                    },

                    ]
                }}
                onClick={
                    () => loadOne(entity.filename)
                }>Load</Dropdown.Button>,
        },
    ];

    const loadOne = (filename: string) => {
        // this.setState({filesPolling: 2000})
        notification.info({message: 'Started loading'})
        postRequest('/api/paymentCycles/loadOne/' + props.params.id, {filename})
            .then(value => {
                console.log(value)
                notification.success({message: 'Success'})
                // this.setState({filesPolling: undefined})

            }).catch(error => {
            let apiError = error.response.data as API.ApiError;
            notification.error({message: 'Error ' + error.response.status, description: apiError.message});

        })
    }

    const unloadOne = (filename: string) => {
        // this.setState({filesPolling: 2000})
        notification.info({message: 'Started unloading'})
        postRequest('/api/paymentCycles/unloadOne/' + props.params.id, {filename})
            .then(value => {
                console.log(value)
                notification.success({message: `Success (${value.data?.rows_affected} rows affected)`})
                // this.setState({filesPolling: undefined})

            }).catch(error => {
            let apiError = error.response.data as API.ApiError;
            notification.error({message: 'Error ' + error.response.status, description: apiError.message});

        })
    }

    return (
        <>

            {/*<Typography.Title level={4}>Payment Cycle {this.props.cycle_id} Logs</Typography.Title>*/}

            <ConfigProvider locale={enUSIntl}>

                {/*<Card bodyStyle={{padding: 0}}>*/}

                <ProTable<PaymentCycleFile>
                    columns={columns}
                    // actionRef={this.state.actionRef as any}
                    // cardBordered
                    bordered
                    // size={"small"}
                    // style={{fontSize:6}}
                    // showHeader={false}
                    // headerTitle={"Logs"}
                    polling={props.polling || 0}
                    request={async (params = {}, sort, filter) => {
                        const res = await postRequest('/api/paymentCycles/getFiles/' + props.params.id)
                        const data = res.data || []
                        // console.log(res.data)
                        return {data, success: true, total: data.length}
                    }}
                    rowKey="filename"
                    search={false}
                    locale={{emptyText: <Space direction={'vertical'}> <FileTextOutlined/> No files</Space>}}
                    pagination={{pageSize: props.pageSize || 10, hideOnSinglePage: true}}
                    dateFormatter="string"
                    toolbar={{style: {marginBottom: 20, textAlign: "end"}}}
                    // toolBarRender={false}
                    // toolBarRender={() => []}
                />
                {/*</Card>*/}

                {describeModalOpen &&
                    <Modal
                        title="Describe File"
                        open={describeModalOpen}
                        // onOk={() => setDescribeModalOpen(false)}
                        onCancel={() => setDescribeModalOpen(false)}
                        okButtonProps={{style: {display: 'none'}}}
                        cancelButtonProps={{style: {display: 'none'}}}
                    >
                        <ProTable<any>
                            columns={[
                                {title: 'Column', dataIndex: 'name'},
                                {title: 'Type', dataIndex: 'type'},
                            ]}
                            request={async () => {
                                const res = await axiosApiInstance.get(`/api/paymentCycles/describeFile/${props.params.id}`, {params: {filepath: describeModalFilepath}})
                                // message.info(res.data?.length)
                                return {data: res.data, success: true, total: res.data?.length}
                            }}
                            rowKey="name"
                            search={false}
                            toolBarRender={false}
                            pagination={{pageSize: 10}}
                        />
                    </Modal>
                }

                {unloadFileModalOpen &&
                    <Modal
                        title="Unload File"
                        open={unloadFileModalOpen}
                        onOk={() => {
                            unloadOne(unloadFileModalFilename)
                            setUnloadFileModalOpen(false)
                        }}
                        onCancel={() => setUnloadFileModalOpen(false)}
                        okText={'Yes'}
                    >
                        <p>Are you sure you want to unload <b>{unloadFileModalFilename}</b>?</p>
                    </Modal>
                }

            </ConfigProvider>

        </>
    );

}

export default withRouter(PaymentCycleFiles);