import * as React from 'react';
import { useState } from 'react';
import axiosApiInstance from '../../../api/axiosClient';
import { Button, notification, Tooltip } from 'antd';
import { ModalForm } from '@ant-design/pro-components';
import { ExportOutlined } from '@ant-design/icons';
import { queryClientAtom } from 'jotai-tanstack-query';
import { useAtomValue } from 'jotai';
import { ProFormRadio, ProFormCheckbox } from '@ant-design/pro-form';

type Props = {
    upc_ean: string;
};

const ModifyRelease = (props: Props) => {
    const queryClient = useAtomValue(queryClientAtom);
    const [loading, setLoading] = useState(false);

    return (
        <ModalForm
            width={400}
            title={'Modify Release'}
            trigger={
                <Tooltip title={'Modify Release...'}>
                    <Button
                        type={'link'}
                        icon={<ExportOutlined />}
                        // icon={<DeleteOutlined/>}
                    />
                </Tooltip>
            }
            // submitter={{submitButtonProps: {danger: true}}}
            autoFocusFirstInput
            modalProps={{
                destroyOnClose: true,
                closable: !loading,
                maskClosable: !loading,
                okText: 'Upload',
                cancelText: 'Cancel'
            }}
            onFinish={async (params) => {
                // message.info(values.upload_to)
                console.log(params);
                setLoading(true);
                return (
                    axiosApiInstance
                        // .put(`/api/products/releases/${props.upc_ean}/upload/${values.upload_to}`)
                        .put(`/api/products/releases/${props.upc_ean}/modify`, undefined, {
                            params
                        })
                        .then((res) => {
                            // console.log(res)
                            setLoading(false);
                            // queryClient.invalidateQueries({queryKey: ['creators','items']})
                            notification.success({
                                message: 'Success',
                                description: 'Release modified successfully'
                            });
                            // queryClient.invalidateQueries({queryKey: ['creators']})
                            return true;
                        })
                        .catch((reason) => {
                            setLoading(false);
                            return false;
                        })
                );
            }}
        >
            {/* <ProFormRadio.Group
                name='upload_to'
                label='Upload to'
                required
                requiredMark={false}
                initialValue={'youtube'}
                options={[
                    {
                        label: 'YouTube',
                        value: 'youtube'
                    },
                    {
                        label: 'Believe',
                        value: 'believe'
                    },
                    {
                        label: 'Both',
                        value: 'both'
                    }
                ]}
            /> */}
            <ProFormCheckbox.Group
                name='platform'
                label='Modify Platform'
                required
                requiredMark={false}
                options={[
                    {
                        label: 'YouTube',
                        value: 'youtube'
                    },
                    {
                        label: 'Believe',
                        value: 'believe'
                    },
                    {
                        label: 'Spotify',
                        value: 'spotify'
                    }
                ]}
            />
        </ModalForm>
    );
};

export default ModifyRelease;
